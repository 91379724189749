import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import CypressIds from '../../../cypressIds';

const DeleteModal = ({ onClose, onSubmit }) => (
  <Dialog open>
    <DialogTitle>
      <Typography variant='h5' component='p'>
        Locatie verwijderen?
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant='body2' component='span'>
        Weet je zeker dat je deze locatie wilt verwijderen? Alle adressen worden losgekoppeld van
        deze locatie.
      </Typography>
    </DialogContent>
    <DialogActions
      sx={{
        py: 1.5
      }}
    >
      <Button color='neutral' onClick={onClose}>
        Annuleren
      </Button>
      <Button
        variant='contained'
        color='error'
        onClick={() => onSubmit?.()}
        data-cy={CypressIds.COMMON_CONFIRM_BUTTON}
      >
        Verwijderen
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteModal;
