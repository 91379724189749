import { MoreVert } from '@mui/icons-material';
import { IconButton, Tooltip, touchRippleClasses } from '@mui/material';
import { ICON_LIGHT } from '@constants';

const MenuOpenButton = ({ open, handleOpen, isDisabled, tooltip, dataCy }) => (
  <Tooltip title={tooltip ?? ''}>
    <IconButton
      disabled={isDisabled}
      onClick={handleOpen}
      data-cy={dataCy}
      sx={{
        [`.${touchRippleClasses.root}`]: {
          background: open ? 'rgba(32, 33, 36, 0.08)' : ''
        }
      }}
    >
      <MoreVert sx={{ color: ICON_LIGHT }} />
    </IconButton>
  </Tooltip>
);

export default MenuOpenButton;
