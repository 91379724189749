import { Divider } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import useMapContext from '@hooks/useMapContext';
import {
  MapControlContainer,
  MapControlButton,
  MapZoomControls,
  MapSettingControlsWrapper
} from './styled';
import FullScreenControl from './buttons/FullScreenControl';
import MapSettings from './buttons/Settings';
import SelectBuildings from './buttons/SelectBuildings';
import ZoomInfo from './ZoomInfo';
import CypressIds from '../../../cypressIds';

const MapControls = () => {
  const { map } = useMapContext();

  return (
    <>
      <MapZoomControls spacing={1.5}>
        <FullScreenControl />
        <MapControlContainer>
          <MapControlButton
            onClick={() => {
              map?.zoomIn();
            }}
            data-cy={CypressIds.MAP_ZOOM_IN_BUTTON}
          >
            <Add />
          </MapControlButton>
          <Divider
            orientation='horizontal'
            sx={{
              mx: 1
            }}
          />
          <MapControlButton
            onClick={() => {
              map?.zoomOut();
            }}
            data-cy={CypressIds.MAP_ZOOM_OUT_BUTTON}
          >
            <Remove />
          </MapControlButton>
        </MapControlContainer>
        <ZoomInfo />
      </MapZoomControls>
      <MapSettingControlsWrapper direction='row' spacing={1.5}>
        <MapControlContainer direction='row'>
          <SelectBuildings />
        </MapControlContainer>
        <MapControlContainer direction='row'>
          <MapSettings />
        </MapControlContainer>
      </MapSettingControlsWrapper>
    </>
  );
};

export default MapControls;
