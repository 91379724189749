import { Close, SearchRounded } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';

import SearchInput from './SearchInput';
import { ICON_DARK, ICON_LIGHT } from '../../../constants';
import CypressIds from '../../../../cypressIds';

const InputSearch = ({
  withSearchIcon,
  onSearchClose,
  searchValue,
  onSearchChange,
  shouldHaveEasyAccessShortcut,
  isFocused,
  ...props
}) => {
  const shouldShowCloseIcon = searchValue && onSearchClose;
  const shouldShowEasyAccessShortcut = shouldHaveEasyAccessShortcut && !isFocused;

  return (
    <SearchInput
      data-cy={CypressIds.SEARCH_INPUT}
      value={searchValue}
      onChange={onSearchChange}
      InputProps={{
        startAdornment: withSearchIcon && (
          <InputAdornment position='start'>
            <SearchRounded sx={{ color: ICON_LIGHT }} />
          </InputAdornment>
        ),
        endAdornment: shouldShowCloseIcon ? (
          <InputAdornment position='end'>
            <Tooltip
              title={
                <Box display='flex' alignItems='center' gap='5px'>
                  <Typography variant='body5'>Close search</Typography>
                  <Box
                    sx={{
                      px: 0.5,
                      background: 'grey',
                      borderRadius: 0.5
                    }}
                  >
                    esc
                  </Box>
                </Box>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  '&:hover': {
                    background: 'none'
                  }
                }}
                onClick={onSearchClose}
              >
                <Close
                  sx={{
                    color: searchValue ? ICON_DARK : ICON_LIGHT
                  }}
                />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : (
          shouldShowEasyAccessShortcut && (
            <InputAdornment position='end'>
              <Box
                sx={(theme) => ({
                  color: theme.palette.neutral.light,
                  background: theme.palette.common.white,
                  width: 20,
                  height: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.pxToRem(14),
                  border: `1px solid ${theme.palette.neutral[200]}`
                })}
              >
                /
              </Box>
            </InputAdornment>
          )
        )
      }}
      {...props}
    />
  );
};

export default InputSearch;
