import { Options } from 'ky';
import { ILocationResponse, ILocationData } from '@types';
import BASE_API from './httpService';

interface IGetBuildingParams {
  lngLat: string;
  externalId: string;
}

interface IBatchCreateLocation {
  feature: ILocationData;
  buildingIds: number[];
}

interface IFeatureData {
  PARKING: string;
  GATE: string;
  ENTRANCE: string;
}

interface IFeatureToBuildingAddressData {
  buildingIds: string[];
  features: IFeatureData;
}

interface IFeatureToAddressData {
  addressIds: string[];
  features: IFeatureData[];
}

export interface IBuildingAddressData {
  id: string[];
}

// Buildings ---
const getBuildingsWithIn = <T>(bbox: string, options?: Options) => {
  return BASE_API(`buildings?bbox=${bbox}`, options).json<T>();
};

const getBuildings = (params: IGetBuildingParams, options?: Options) => {
  return BASE_API(`buildings`, {
    searchParams: {
      ...params
    },
    ...options
  }).json();
};

const getBuilding = <T>(buildingId: string, options?: Options) => {
  return BASE_API(`buildings/${buildingId}`, options).json<T>();
};

// ---
const getLocations = (bbox: string, options?: Options) => {
  return BASE_API(`features?bbox=${bbox}`, options).json<ILocationResponse>();
};

const getBuildingAddresses = <T>(buildingId: string, options?: Options) => {
  return BASE_API(`buildings/${buildingId}/addresses`, options).json<T>();
};

const getBuildingLocations = (buildingId: string, options?: Options) => {
  return BASE_API(`buildings/${buildingId}/features`, options).json<ILocationResponse>();
};

// Building Addresses [Global case]
const getBuildingsAddresses = <T>(options?: Options) => {
  return BASE_API.post(`addresses`, options).json<T>();
};

// Locations
const createLocation = (body: ILocationData) => {
  return BASE_API.post(`features`, {
    json: body
  });
};

const removeLocation = (locationId: string) => {
  return BASE_API.delete(`features/${locationId}`).json();
};

const updateLocation = (locationId: string, payload: ILocationData) => {
  return BASE_API.put(`features/${locationId}`, {
    json: payload
  }).json();
};

const editAddressesFeatures = (body: IFeatureToAddressData) => {
  return BASE_API.put(`addresses/features`, {
    json: body
  }).json();
};

// Building Addresses
const batchCreateLocation = (body: IBatchCreateLocation[]) => {
  return BASE_API.post(`buildings/features`, {
    json: body
  });
};

const editBuildingAddressesFeatures = (body: IFeatureToBuildingAddressData) => {
  return BASE_API.put(`buildings/features`, {
    json: body
  }).json();
};

// Depots
const getDepots = <T>(options?: Options) => BASE_API(`depots`, options).json<T>();

export {
  removeLocation,
  updateLocation,
  getBuilding,
  getBuildingAddresses,
  createLocation,
  batchCreateLocation,
  getBuildings,
  getBuildingsWithIn,
  getDepots,
  getBuildingsAddresses,
  getLocations,
  getBuildingLocations,
  editAddressesFeatures,
  editBuildingAddressesFeatures
};
