import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';

import BuildingsDetail from '../../pages/main/buildings/Detail';
import LocationsCreate from '../../pages/main/locations/Create';
import Index from '../../pages/main/Index';
import LocationUpdatePage from '../../pages/main/locations/Update';

const AddressPointModule = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route index path='/' element={<Index />} />
      <Route path='/buildings' element={<BuildingsDetail />} />
      <Route path='/buildings/:buildingId' element={<BuildingsDetail />} />
      <Route path='/buildings/locations/create' element={<LocationsCreate />} />
      <Route path='/buildings/locations/update' element={<LocationUpdatePage />} />
      <Route path='/buildings/locations/create/batch' element={<LocationsCreate />} />
      <Route path='/buildings/locations/:locationId/update' element={<LocationUpdatePage />} />
    </Route>
  </Routes>
);

export default AddressPointModule;
