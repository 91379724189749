import { PinDrop } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import CypressIds from '../../../../cypressIds';

const PickLocationButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Button
      sx={{
        minWidth: 160,
        height: 54,
        border: '1px solid #e2e8f0',
        borderLeft: 'none',
        borderRadius: 0,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4
      }}
      type='button'
      disabled={!onClick}
      data-cy={CypressIds.PICK_LOCATION_BUTTON}
      onClick={() => onClick?.()}
    >
      <PinDrop />
      <Box component='span' ml={2}>
        Kies locatie
      </Box>
    </Button>
  );
};

export default PickLocationButton;
