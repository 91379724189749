import { tabClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          minHeight: 56,
          minWidth: 0,
          padding: outerTheme.spacing(0, 1),
          color: outerTheme.palette.text.secondaryLight,
          transition: outerTheme.transitions.create(['background-color'], {
            duration: outerTheme.transitions.duration.standard
          }),
          '&:hover': {
            color: outerTheme.palette.text.primary,
            backgroundColor: outerTheme.palette.neutral['900-8']
          },
          [`&.${tabClasses.selected}`]: {
            color: outerTheme.palette.text.primary
          },
          [`&.${tabClasses.selected} .tab-count`]: {
            color: outerTheme.palette.text.primary
          }
        }
      }
    }
  }
});

const componentTabTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions(outerTheme));

export default componentTabTheme;
