import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SSO from '@pages/main/SSO';
import AuthModule from './auth';
import MainModule from './main';
import Layout from './auth/Layout';

const Modules = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        <Route path='/auth/*' element={<AuthModule />} />
        <Route path='/dhlbnl-sso' element={<SSO />} />
        <Route path='/*' element={<MainModule />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Modules;
