import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const transitionsOptions: ThemeOptions = {
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  }
};

const transitionsTheme = (outerTheme: Theme) => createTheme(outerTheme, transitionsOptions);

export default transitionsTheme;
