import useMapContext from '@hooks/useMapContext';
import { mapLayerAtom } from '@store';
import { Box, Typography } from '@mui/material';
import { MapLayerType } from 'types/map';
import satelliteImg from '@assets/layers/satellite.jpg';
import mapImg from '@assets/layers/map.jpg';
import getMapStyle from '@utils/map/style';
import { useMapDataFetchCallbackContext } from '@contexts/FetchMapDataContext';
import { MapLayerSettingsWrapper } from './styled';
import CypressIds from '../../../cypressIds';

const MapLayerSettings = () => {
  const [mapLayer, setMapLayer] = useAtom(mapLayerAtom);
  const handleMapDataFetch = useMapDataFetchCallbackContext();
  const { map } = useMapContext();
  const isSatellite = mapLayer === MapLayerType.SATELLITE;
  const previousLayer = useRef(mapLayer);

  const handleMapLayerChange = (layer: MapLayerType) => {
    setMapLayer(layer);
    localStorage.setItem('mapLayer', layer);
  };

  useEffect(() => {
    if (previousLayer.current === mapLayer) return;
    previousLayer.current = mapLayer;
    const styleURL = getMapStyle(mapLayer as MapLayerType);
    Promise.resolve()
      .then(() => {
        map?.setStyle(styleURL);
      })
      .then(() => {
        handleMapDataFetch();
      });
  }, [handleMapDataFetch, map, mapLayer]);

  const imageURL = isSatellite ? mapImg : satelliteImg;

  return (
    <MapLayerSettingsWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          alignItems: 'center',
          width: 80,
          height: 80,
          cursor: 'pointer',
          borderRadius: 2,
          border: '4px solid white',
          background: `url(${imageURL})`,
          '&:hover': {
            border: '5px solid white'
          }
        }}
        data-cy={CypressIds.MAP_LAYER_SETTINGS_BUTTON}
        onClick={() => {
          if (isSatellite) {
            handleMapLayerChange(MapLayerType.DEFAULT);
            return;
          }

          handleMapLayerChange(MapLayerType.SATELLITE);
        }}
      >
        <Typography
          mb={1}
          sx={{
            color: isSatellite ? 'black' : 'white',
            fontWeight: 500,
            fontSize: (theme) => theme.typography.pxToRem(14)
          }}
          data-cy={CypressIds.MAP_LAYER_NAME}
        >
          {isSatellite ? 'Kaart' : 'Satelliet'}
        </Typography>
      </Box>
    </MapLayerSettingsWrapper>
  );
};

export default MapLayerSettings;
