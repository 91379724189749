import { Box, Typography, styled } from '@mui/material';

export const SectionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.neutral.light
}));

export const SectionLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.neutral.dark,
  fontSize: theme.typography.pxToRem(14)
}));

export const SectionValue = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.neutral.dark,
  fontSize: theme.typography.pxToRem(13),
  display: 'flex',
  alignItems: 'center',
  gap: 3
}));
