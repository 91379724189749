import { depotsAtom, isFetchingDepotsAtom, userAtom } from '@store';

import CircularLoading from '@components/ui-kit/CircularLoading';
import { useAtom } from 'jotai';
import SidebarHeader from '@components/sidebar/SidebarHeader';
import IconRoutetitan from '@assets/icons/Routetitan';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import AddressLocations from './main/locations';
import CypressIds from '../../../cypressIds';

const Index = () => {
  const theme = useTheme();
  const [depots] = useAtom(depotsAtom);
  const [isFetchingDepots] = useAtom(isFetchingDepotsAtom);
  const [user] = useAtom(userAtom);
  const isAdmin = ['ADMIN', 'SUPER_ADMIN'].includes(user?.role as string);
  // eslint-disable-next-line no-nested-ternary
  const adminName = isAdmin ? (user?.role === 'SUPER_ADMIN' ? 'SADMIN' : user?.role) : '';

  const userInfo =
    adminName || depots?.find((depot) => `${depot?.id}` === `${user?.depotId}`)?.name;

  return (
    <>
      <SidebarHeader
        title={
          <Grid container px={0} gap={1}>
            <Grid item container gap={1}>
              <IconRoutetitan
                viewBox='0 0 32 32'
                sx={{
                  fontSize: theme.typography.pxToRem(24)
                }}
              />
              <Divider orientation='vertical' flexItem />
              {isFetchingDepots ? (
                <CircularLoading size={24} />
              ) : (
                <Typography variant='h6'>{userInfo}</Typography>
              )}
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: theme.typography.pxToRem(14),
                  color: theme.palette.neutral[600]
                }}
                data-cy={CypressIds.ADDRESS_INTELLIGENCE_PLATFORM_TITLE}
              >
                Address Intelligence Platform
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <Grid
        sx={{
          borderBottom: `1px solid ${theme.palette.neutral[200]}`
        }}
      />
      <AddressLocations />
    </>
  );
};

export default Index;
