import { useState } from 'react';
import { Tune } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { MapControlButton } from '../styled';
import SettingsMenu from '../menu/SettingsMenu';
import CypressIds from '../../../../cypressIds';

const MapSettings = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Tooltip title='Kaartinstellingen' placement='bottom-end'>
        <MapControlButton
          aria-controls={open ? 'settings-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            color: (theme) => (open ? theme.palette.primary.main : '')
          }}
          data-cy={CypressIds.MAP_SETTINGS_BUTTON}
        >
          <Tune />
        </MapControlButton>
      </Tooltip>
      <SettingsMenu anchorElement={anchorElement} handleClose={handleClose} />
    </>
  );
};

export default MapSettings;
