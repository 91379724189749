import { Grid, SxProps, useTheme } from '@mui/material';
import BoundedText from '../../bounded-text';

const DefaultItemInfo = ({
  mainTitle = '',
  isMainTitleSemibold,
  secondaryTitle,
  highlightSearchedValue,
  searchValue,
  color,
  sx
}: {
  mainTitle?: string;
  isMainTitleSemibold?: boolean;
  secondaryTitle?: string | React.ReactNode;
  highlightSearchedValue?: (value: string, searchValue: string) => JSX.Element;
  searchValue?: string;
  color?: string;
  sx?: SxProps;
}) => {
  const theme = useTheme();

  return (
    <Grid container flexDirection='column' sx={sx}>
      <BoundedText
        maxWidth={259}
        initialWidth={259}
        text={mainTitle}
        textSx={{
          color: theme.palette.neutral[900],
          fontSize: theme.typography.pxToRem(14),
          fontWeight: isMainTitleSemibold ? 500 : 400
        }}
      >
        {searchValue ? highlightSearchedValue?.(mainTitle, searchValue) : mainTitle}
      </BoundedText>

      {/* if it's default string otherwise it can be ReactElement */}
      {typeof secondaryTitle === 'string' ? (
        <BoundedText
          maxWidth={259}
          initialWidth={259}
          text={secondaryTitle}
          textSx={{
            color: color ?? theme.palette.neutral[600],
            fontSize: theme.typography.pxToRem(13)
          }}
        >
          {searchValue ? highlightSearchedValue?.(secondaryTitle, searchValue) : secondaryTitle}
        </BoundedText>
      ) : (
        secondaryTitle
      )}
    </Grid>
  );
};

export default DefaultItemInfo;
