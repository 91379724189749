import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import useMapContext from '@hooks/useMapContext';
import { Add } from '@mui/icons-material';
import { Button, SxProps } from '@mui/material';
import { REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS } from 'constants/map';
import CypressIds from '../../../cypressIds';

interface AddLocationButtonProps {
  sx?: SxProps;
}

interface AddLocationsButtonProps extends AddLocationButtonProps {
  selectedBuildings: string[];
}

export const AddLocationButton = ({ sx }: AddLocationButtonProps) => {
  const navigate = useAdvancedNavigate();
  const { map } = useMapContext();

  const onAddNewLocation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    if (map?.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS) {
      map?.zoomTo(REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS);
    }
    navigate(`/buildings/locations/create/`);
  };

  return (
    <Button
      data-cy={CypressIds.ADD_FEATURE_BUTTON}
      startIcon={<Add />}
      variant='outlined'
      onClick={onAddNewLocation}
      sx={sx}
    >
      Toevoegen
    </Button>
  );
};

export const AddLocationsButton = ({ sx, selectedBuildings }: AddLocationsButtonProps) => {
  const navigate = useAdvancedNavigate();
  const { map } = useMapContext();

  const changeRouterParams = useChangeRouterSearchParams();

  const onAddNewLocations = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    if (map?.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS) {
      map?.zoomTo(REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS);
    }

    const { promise, resolve } = Promise.withResolvers();
    navigate(`/buildings/locations/create/batch/`, () => {
      resolve(true);
    });
    promise.then(() => {
      changeRouterParams('selectedBuildings', selectedBuildings);
    });
  };

  return (
    <Button
      data-cy={CypressIds.ADD_FEATURES_BUTTON}
      startIcon={<Add />}
      variant='outlined'
      onClick={onAddNewLocations}
      sx={sx}
    >
      Locaties toevoegen
    </Button>
  );
};
