import { Tooltip, Typography } from '@mui/material';
import useMapContext from '@hooks/useMapContext';
import { getFocusParams } from '@utils/windowParams';
import { MapControlContainer, MapInfoItem } from './styled';
import CypressIds from '../../../cypressIds';

const ZoomInfo = () => {
  const { map } = useMapContext();
  const zoomFromWindow = getFocusParams().zoom;
  const zoom = Number.isNaN(Number(zoomFromWindow)) ? map?.getZoom() : Number(zoomFromWindow);

  const mapZoom = zoom?.toFixed(1) || 0;
  const [zoomLevel, setZoomLevel] = useState(mapZoom);

  useEffect(() => {
    map?.on('zoomend', () => {
      const zoom = map.getZoom().toFixed(1);
      setZoomLevel(zoom);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  if (!zoomLevel) return null;

  return (
    <Tooltip title='Zoomniveau' placement='left'>
      <MapControlContainer>
        <MapInfoItem
          sx={{
            bgcolor: 'white',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(12)
            }}
            data-cy={CypressIds.ZOOM_INFO}
          >
            {zoomLevel}
          </Typography>
        </MapInfoItem>
      </MapControlContainer>
    </Tooltip>
  );
};

export default ZoomInfo;
