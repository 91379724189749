import { createTheme, Theme } from '@mui/material/styles';

const customShadows = [
  '0px 0px 12px rgba(0, 0, 0, 0.12)',
  '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
  '2px 0px 12px rgba(0, 0, 0, 0.24)',
  '0px 0px 16px rgba(0, 0, 0, 0.08)',
  '0px 3px 6px rgba(0, 0, 0, 0.16)'
];

const themeOptions = (outerTheme: Theme) => ({
  shadows: outerTheme.shadows.map((shadow, index) =>
    customShadows[index - 1] ? customShadows[index - 1] : shadow
  )
});

const shadowsTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions(outerTheme));

export default shadowsTheme;
