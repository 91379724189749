import { useCallback } from 'react';
import { Grid, SxProps, useTheme } from '@mui/material';
import StopItem from './items/Stop';
import { ListItem, ListItemType } from '../../constants';
import AddressItem from './items/Address';
import RosterItem from './items/Roster';

const Item = ({
  index,
  handleCurrentItemChange,
  highlightSearchedValue,
  handleItemClick,
  item,
  searchValue,
  currentItem,
  selectedListItems,
  setSelectedItems,
  type,
  sx
}: {
  index?: number;
  handleCurrentItemChange: (item: ListItem | null) => void;
  highlightSearchedValue?: (value: string, searchValue: string) => JSX.Element;
  handleItemClick: (item: ListItem) => void;
  item: ListItem;
  searchValue?: string;
  currentItem: ListItem | null;
  selectedListItems?: ListItem[];
  setSelectedItems?: (items: ListItem[]) => void;
  type: ListItemType;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const isCurrentItem = currentItem?.id === item.id;
  const handleMouseLeave = useCallback(() => {
    handleCurrentItemChange(null);
  }, [handleCurrentItemChange]);
  const isSelected = !!selectedListItems?.find(
    (listItem: { id: number }) => listItem.id === item.id
  );

  const handleMouseEnter = useCallback(() => {
    return handleCurrentItemChange(item);
  }, [handleCurrentItemChange, item]);

  return (
    <Grid
      key={index}
      container
      sx={{
        background: isCurrentItem ? theme.palette.neutral[100] : 'transparent',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
          opacity: 1
        },
        border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.neutral[200]}`,
        borderRadius: 1,
        ...sx
      }}
      px={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleItemClick(item)}
      pt={1.125}
      pb={0.75}
    >
      {type === ListItemType.Stop && (
        <StopItem
          item={item}
          highlightSearchedValue={highlightSearchedValue}
          searchValue={searchValue}
          currentItem={currentItem}
          type={type}
          index={index as number}
          selectedListItems={selectedListItems}
          setSelectedItems={setSelectedItems}
        />
      )}

      {type === ListItemType.Roster && (
        <RosterItem
          item={item}
          highlightSearchedValue={highlightSearchedValue}
          searchValue={searchValue}
          currentItem={currentItem}
          selectedListItems={selectedListItems}
          setSelectedItems={setSelectedItems}
        />
      )}
      {type === ListItemType.Address && (
        <AddressItem
          item={item}
          highlightSearchedValue={highlightSearchedValue}
          searchValue={searchValue}
          currentItem={currentItem}
          type={type}
          index={index as number}
          selectedListItems={selectedListItems}
          setSelectedItems={setSelectedItems}
        />
      )}
    </Grid>
  );
};

export default memo(Item);
