import { useContext } from 'react';
import { HelperCanvasContext } from '@providers/HelperCanvas';

const useHelperCanvasRef = () => {
  const canvasRef = useContext(HelperCanvasContext);

  return canvasRef?.current;
};

export default useHelperCanvasRef;
