import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import muiTheme from './mui-theme';

import './styles/global.scss';

const ThemeProvider = ({ children }: PropsWithChildren) => (
  <MUIThemeProvider theme={muiTheme}>
    <CssBaseline />
    {children}
  </MUIThemeProvider>
);

export default ThemeProvider;
