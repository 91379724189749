const useAdvancedNavigate = () => {
  const navigate = useNavigate();

  return useCallback((to: string, successCallback?: () => void) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (to.includes('auth')) {
      navigate(to);
      return;
    }
    const { promise, resolve } = Promise.withResolvers();
    resolve(true);

    promise
      .then(() => {
        navigate(`${to}?${searchParams.toString().replace(/%2C/g, ',')}`, {
          replace: true
        });
      })
      .then(() => {
        if (successCallback) successCallback();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAdvancedNavigate;
