export const ICON_LIGHT = 'neutral.light';
export const ICON_DARK = 'neutral.dark';
export const ICON_DISABLED = 'neutral.200';

export const BUILDING_SELECT_COLOR = '#1329fe3d';

export const BUILDING_COLOR = '#eceff4a2';

export const ITEM_COLORS = [
  '#4363D8',
  '#E6194B',
  '#2EB67D',
  '#8039DF',
  '#FF7800',
  '#F032E6',
  '#469199',
  '#F9A825',
  '#800033',
  '#36C5F0',
  '#C47E2E',
  '#546E7A',
  '#1DECBF',
  '#AAAA00'
];
