import { Route, Routes } from 'react-router-dom';
import Login from '../../pages/auth/Login';
import Layout from './Layout';

const AuthModule = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route index path='/login/' element={<Login />} />
    </Route>
  </Routes>
);

export default AuthModule;
