import { ListItem } from '@constants';
import { Grid, GridProps, Checkbox, Tooltip, Typography } from '@mui/material';

interface IBuildingAddressSelectDetails extends GridProps {
  listItems: ListItem[];
  selectedListItems: ListItem[];
  setSelectedListItems: (items: ListItem[]) => void;
}

const Details = ({
  listItems,
  selectedListItems,
  setSelectedListItems,
  ...props
}: IBuildingAddressSelectDetails) => {
  const selectedListItemsCount = selectedListItems.length;

  const areAllListItemsSelected = listItems.every((listItem) => {
    return selectedListItems.find((selectedListItem) => selectedListItem.id === listItem.id);
  });

  const tooltipMessage = areAllListItemsSelected
    ? 'Alles deselecteren'
    : selectedListItemsCount > 0 && 'Alles selecteren';

  return (
    <Grid display='flex' alignItems='center' {...props}>
      <Tooltip title={tooltipMessage}>
        <Checkbox
          sx={{
            '& > svg': {
              fontSize: (theme) => theme.typography.pxToRem(24)
            },
            p: 1
          }}
          indeterminate={!areAllListItemsSelected}
          checked={areAllListItemsSelected}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedListItems(
                (prev: ListItem[]) => Array.from(new Set([...prev, ...listItems])) as ListItem[]
              );
            } else {
              setSelectedListItems([]);
            }
          }}
        />
      </Tooltip>
      <Typography
        color='primary'
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 500,
          pl: 1
        })}
      >
        {`${selectedListItemsCount} geselecteerd`}
      </Typography>
    </Grid>
  );
};

export default Details;
