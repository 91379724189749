/* eslint-disable @typescript-eslint/no-explicit-any */
import { FmdGood } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Fragment } from 'react';
import LeftSideCell from '../../leftSideCell';
import DefaultItemInfo from './DefaultItem';
import { ListItemType } from '../../../constants';
import formatAddress from '../../../helpers/formatAddress';
import AddressItemInfo from './AddressItemInfo';
import CypressIds from '../../../../cypressIds';

const AddressItem = ({
  highlightSearchedValue,
  item,
  searchValue,
  currentItem,
  disableCheck,
  selectedListItems,
  setSelectedItems,
  type,
  index
}: {
  highlightSearchedValue?: (value: string, searchValue: string) => JSX.Element;
  item: any;
  searchValue?: string;
  currentItem?: any;
  disableCheck?: boolean;
  selectedListItems?: any[];
  setSelectedItems?: any;
  type: ListItemType;
  index: number;
}) => {
  const isCurrentItem = currentItem?.id === item.id;

  const canCellBecomeCheckable = !disableCheck && type !== ListItemType.Stop;
  const { mainTitle, secondaryTitle } = formatAddress(item);

  return (
    <>
      <Grid
        container
        flexWrap='nowrap'
        alignItems='flex-start'
        gap={2.5}
        sx={{
          px: 1
        }}
        data-cy={`${CypressIds.ADDRESS_ITEM}-${index}`}
      >
        {/* Cell can become selectable or not it's up to conditions or combination of conditions which will decide is the left side cell can become checkable or not */}
        <LeftSideCell
          defaultIcon={FmdGood}
          canBecomeCheckable={canCellBecomeCheckable}
          isCurrentItem={isCurrentItem}
          item={item}
          index={index}
          selectedListItems={selectedListItems}
          setSelectedItems={setSelectedItems}
        />
        <Grid container item xs={12} gap={1} display='flex'>
          <Fragment key={item.id}>
            <DefaultItemInfo
              sx={{
                pt: 0.5
              }}
              mainTitle={mainTitle ?? ''}
              secondaryTitle={secondaryTitle ?? ''}
              highlightSearchedValue={highlightSearchedValue}
              searchValue={searchValue}
            />
          </Fragment>
        </Grid>
      </Grid>
      <AddressItemInfo item={item} />
    </>
  );
};

export default memo(AddressItem);
