import { Logout } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { USER_AUTH_STATUS, APP_VERSION } from '@constants';
import { clearAllMarkers } from '@utils/map';
import { userAtom, userAuthStatusAtom } from '@store';
import { ReactNode } from 'react';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import authService from '@services/authService';
import SimpleMenu from '../simple-menu';
import CypressIds from '../../../cypressIds';

const SidebarHeader = ({ title }: { title: string | ReactNode }) => {
  const navigate = useAdvancedNavigate();
  const [, setUserAuthStatus] = useAtom(userAuthStatusAtom);
  const [, setUser] = useAtom(userAtom);
  const handleLogout = () => {
    authService?.logout();
    setUser(null);
    setUserAuthStatus(USER_AUTH_STATUS.FAIL);
    clearAllMarkers();
    navigate('/auth/login');
  };

  return (
    <Box
      component='header'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 2,
        py: 2
      }}
    >
      <Grid
        sx={{
          display: 'flex'
        }}
      >
        {typeof title === 'string' ? <Typography variant='h4'>{title}</Typography> : title}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <SimpleMenu
          config={{
            items: [
              [
                {
                  name: 'Uitloggen',
                  onClick: handleLogout,
                  icon: Logout,
                  dataCy: CypressIds.SIGN_OUT_BUTTON
                }
              ]
            ],
            width: 150
          }}
          dataCy={CypressIds.USER_MENU_BUTTON}
          tooltip='Menu'
          footer={
            <Typography
              pt={1}
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
              variant='caption'
              textAlign='center'
            >
              Version {APP_VERSION}
            </Typography>
          }
        />
      </Grid>
    </Box>
  );
};

export default SidebarHeader;
