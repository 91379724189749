import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 56
        },
        indicator: {
          height: 3,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          padding: outerTheme.spacing(0, 1),
          '& > span': {
            width: '100%',
            backgroundColor: outerTheme.palette.primary.main,
            borderRadius: outerTheme.spacing(0.5, 0.5, 0, 0)
          }
        },
        flexContainer: {
          gap: 12,
          borderWidth: 1,
          borderBottomStyle: 'solid',
          borderColor: outerTheme.palette.neutral['200']
        }
      }
    }
  }
});

const componentTabsTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions(outerTheme));

export default componentTabsTheme;
