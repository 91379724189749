import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral?: true;
  }
}

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: outerTheme.typography.pxToRem(20)
        },
        fontSizeLarge: {
          fontSize: outerTheme.typography.pxToRem(24)
        },
        fontSizeSmall: {
          fontSize: outerTheme.typography.pxToRem(18)
        }
      }
    }
  }
});

const componentSvgIconTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentSvgIconTheme;
