import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import { JSX } from 'react/jsx-runtime';

const Switch = (props: JSX.IntrinsicAttributes & SwitchProps) => {
  const { icons } = props;

  return (
    <MuiSwitch
      {...{
        ...props,
        ...(icons ? { icon: <RemoveRoundedIcon />, checkedIcon: <CheckRoundedIcon /> } : {})
      }}
    />
  );
};

Switch.displayName = 'Switch';

export default Switch;
