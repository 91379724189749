import { Grid } from '@mui/material';
import CircularLoading from '../ui-kit/CircularLoading';

const PageLoading = () => (
  <Grid
    sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularLoading size={40} />
  </Grid>
);

export default PageLoading;
