const useChangeRouterSearchParams = () => {
  return (prop: string, value?: string) => {
    const params = new URLSearchParams(window.location.search);
    if (!value) {
      params.delete(prop);
    } else {
      params.set(prop, value);
    }

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params.toString().replace(/%2C/g, ',')}`
    );
  };
};

export default useChangeRouterSearchParams;
