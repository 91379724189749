/* eslint-disable import/prefer-default-export */
export const mergeUniqueItemsArray = <T extends { id: string }>(
  source: T[],
  additions: T[]
): T[] => {
  const map = new Map<string, T>();

  source.forEach((item) => map.set(item.id, item));

  additions.forEach((item) => {
    if (!map.has(item.id)) {
      map.set(item.id, item);
    }
  });

  return Array.from(map.values());
};
