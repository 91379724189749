import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import useCalculateTextWidth from '../../hooks/use-calculate-text-width';

// textWrapper with to control text length manually or you can do it without that just give width to your boundedText parent component.
const BoundedText = ({
  text,
  children,
  sx,
  textSx,
  initialWidth,
}: {
  text: string;
  children: React.ReactNode;
  sx?: SxProps;
  textSx?: SxProps;
  initialWidth?: number;
}) => {
  const [textFieldWrapperWidth, setTextFieldWidth] = useState(initialWidth);
  const textRef = useRef<HTMLElement>();
  const getTextWidth = useCalculateTextWidth();
  useEffect(() => {
    if (textRef.current && !textFieldWrapperWidth) {
      setTextFieldWidth(textRef.current.clientWidth);
    }
  }, [textFieldWrapperWidth]);

  const shouldReplaceLongTitleWithDots = useMemo(() => {
    if (textFieldWrapperWidth) {
      return getTextWidth(text, 14) > textFieldWrapperWidth - 50;
    }

    return null;
  }, [getTextWidth, text, textFieldWrapperWidth]);

  return (
    <Tooltip title={shouldReplaceLongTitleWithDots ? text : ''}>
      <Box
        ref={textRef}
        sx={{
          display: 'flex',
          '& div': {
            maxWidth: textFieldWrapperWidth,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis'
          },
          ...sx
        }}
      >
        {(textFieldWrapperWidth ?? 0) > 0 && <Typography sx={textSx}>{children}</Typography>}
      </Box>
    </Tooltip>
  );
};

export default BoundedText;
