/* eslint-disable max-lines-per-function */
import { buttonClasses, lighten } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const containedLightVariant = 'contained-light';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }

  interface ButtonPropsVariantOverrides {
    [containedLightVariant]: true;
  }
}

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        // eslint-disable-next-line prettier/prettier
        color: 'primary'
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          lineHeight: 1.6,
          [`&.${buttonClasses.disabled}`]: {
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                backgroundColor: outerTheme.palette.primary.main
              }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'error' && {
                backgroundColor: outerTheme.palette.error.main
              })
          },
          '&.MuiLoadingButton-loading': {
            '& .MuiCircularProgress-root': {
              width: `${ownerState.size === 'small' ? 20 : 24}px !important`,
              height: `${ownerState.size === 'small' ? 20 : 24}px !important`
            },
            '& 	.MuiCircularProgress-svg': {
              borderRadius: '50%',
              ...(ownerState.color === 'primary' && {
                color: outerTheme.palette.primary.main,
                boxShadow: 'inset 0 0 0px 1.5px rgba(19, 41, 254, 0.24)'
              }),
              ...(ownerState.color === 'neutral' && {
                color: outerTheme.palette.neutral.light,
                boxShadow: 'inset 0 0 0px 1.5px rgba(96, 97, 101, 0.24)'
              }),
              ...(ownerState.color === 'error' && {
                color: outerTheme.palette.error.main,
                boxShadow: 'inset 0 0 0px 1.5px rgba(217, 48, 36, 0.24)'
              }),
              ...(ownerState.variant === 'contained' && {
                color: 'white',
                boxShadow: 'inset 0 0 0px 1.5px rgba(255,255,255,0.24)'
              })
            }
          },
          [`&.${buttonClasses.disabled}`]: {
            ...(ownerState.variant === 'text' &&
              ownerState.color === 'neutral' && {
                color: lighten(outerTheme.palette[ownerState.color].main, 0.62)
              }),
            ...(ownerState.variant === 'outlined' && {
              borderColor: lighten(outerTheme.palette.neutral['300'], 0.62)
            }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                color: lighten(outerTheme.palette.primary.main, 0.62)
              }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'neutral' && {
                color: lighten(outerTheme.palette.neutral.light, 0.62)
              }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                color: lighten(outerTheme.palette.primary.contrastText, 0.62),
                backgroundColor: lighten(outerTheme.palette.primary.main, 0.62)
              }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'error' && {
                color: lighten(outerTheme.palette.error.contrastText, 0.62),
                backgroundColor: lighten(outerTheme.palette.error.main, 0.62)
              }),
            ...(ownerState.variant === containedLightVariant && {
              color: lighten(outerTheme.palette.primary.main, 0.62),
              backgroundColor: lighten(outerTheme.palette.primary['100'], 0.62)
            })
          }
        }),
        sizeSmall: ({ ownerState }) => ({
          ...outerTheme.typography.button,
          lineHeight: 1.333334,
          padding: outerTheme.spacing(1, 1.8732),
          ...(ownerState.variant === 'outlined' && {
            padding: outerTheme.spacing(0.875, 1.749)
          })
        }),
        sizeMedium: ({ ownerState }) => ({
          padding: outerTheme.spacing(1, 1.8732),
          ...(ownerState.variant === 'outlined' && {
            padding: outerTheme.spacing(0.876, 1.749)
          })
        }),
        sizeLarge: ({ ownerState }) => ({
          padding: outerTheme.spacing(1, 2.874),
          ...(ownerState.variant === 'outlined' && {
            padding: outerTheme.spacing(0.876, 2.749)
          })
        }),
        text: ({ ownerState }) => ({
          ...(ownerState.color === 'neutral' && {
            color: outerTheme.palette[ownerState.color].main,
            '&:hover': {
              backgroundColor: outerTheme.palette[ownerState.color]['900-8']
            }
          })
        }),
        outlined: ({ ownerState }) => ({
          borderColor: outerTheme.palette.neutral['300'],
          ...(ownerState.color === 'primary' && {
            color: outerTheme.palette.primary.main
          }),
          ...(ownerState.color === 'neutral' && {
            color: outerTheme.palette.neutral.light
          }),
          '&:hover': {
            borderColor: outerTheme.palette.neutral['300'],
            backgroundColor: outerTheme.palette.neutral['900-8']
          }
        }),
        startIcon: ({ ownerState }) => ({
          marginRight: 9,
          marginLeft: -5,
          ...(ownerState.size === 'small' && {
            '& > *:nth-of-type(1)': {
              fontSize: 20
            }
          }),
          ...(ownerState.size === 'medium' && {
            '& > *:nth-of-type(1)': {
              fontSize: 24
            }
          }),
          ...(ownerState.size === 'large' && {
            '& > *:nth-of-type(1)': {
              fontSize: 24
            }
          })
        }),
        endIcon: ({ ownerState }) => ({
          marginRight: -5,
          marginLeft: 9,
          ...(ownerState.size === 'small' && {
            '& > *:nth-of-type(1)': {
              fontSize: 20
            }
          }),
          ...(ownerState.size === 'medium' && {
            '& > *:nth-of-type(1)': {
              fontSize: 24
            }
          }),
          ...(ownerState.size === 'large' && {
            '& > *:nth-of-type(1)': {
              fontSize: 24
            }
          })
        })
      },
      variants: [
        {
          props: { variant: containedLightVariant },
          style: {
            color: outerTheme.palette.primary.main,
            background: outerTheme.palette.primary['100'],
            '&:hover': {
              background: outerTheme.palette.primary['200']
            }
          }
        }
      ]
    }
  }
});

const componentButtonTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentButtonTheme;
