import { Box, Skeleton } from '@mui/material';
import { v4 } from 'uuid';

const BuildingAddressesSectionPlaceholder = () => {
  return (
    <Box>
      <Skeleton variant='rectangular' width='100%' height={50} />
      {Array.from({ length: 3 }).map(() => (
        <Box key={v4()} sx={{ mt: 2 }}>
          <Skeleton variant='rectangular' width='100%' height={100} />
          <Skeleton variant='text' width='100%' height={16} sx={{ mt: 1 }} />
        </Box>
      ))}
    </Box>
  );
};

export default BuildingAddressesSectionPlaceholder;
