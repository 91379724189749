import { Fragment, MouseEvent, ReactElement, useState } from 'react';
import {
  Divider,
  FormControl,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIconTypeMap,
  Typography
} from '@mui/material';

import { OverridableComponent } from '@mui/material/OverridableComponent';
import MenuOpenButton from './AnchorButton';

interface IMenuItem {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  onClick: () => void;
  closeAfterClick?: boolean;
  color?: string;
  dataCy?: string;
}

interface IMenuConfig {
  items: IMenuItem[][];
  width: number;
}

interface SimpleMenuProps {
  config: IMenuConfig;
  shouldStartWithDivider?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  footer?: ReactElement;
  dataCy: string;
}

const SimpleMenu = ({
  config,
  shouldStartWithDivider,
  isDisabled,
  tooltip,
  footer,
  dataCy
}: SimpleMenuProps) => {
  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Constants
  const open = Boolean(anchorEl);

  // Functions
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const width = config.width ?? 220;

  return (
    <>
      <MenuOpenButton
        handleOpen={handleOpen}
        open={open}
        isDisabled={isDisabled}
        tooltip={tooltip}
        dataCy={dataCy}
      />
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={{
          '& .MuiBackdrop-root': {
            background: 'transparent'
          }
        }}
        PaperProps={{
          sx: {
            mt: 0.5,
            borderRadius: 1
          }
        }}
        anchorOrigin={{
          horizontal: -width + 30,
          vertical: 40
        }}
        disableEnforceFocus
        disableRestoreFocus
      >
        <FormControl fullWidth>
          {shouldStartWithDivider && (
            <Divider
              sx={{
                mt: 1
              }}
            />
          )}
          {config.items.map((row, index) => {
            const shouldAddDivider = config.items.length - 1 > index;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {row.map((menuItem) => (
                  <MenuItem
                    disableRipple
                    sx={{
                      p: 0,
                      width,
                      '& hover': {
                        background: (theme) => theme.palette.neutral[100]
                      }
                    }}
                    key={menuItem.name}
                    
                    data-cy={menuItem.dataCy}
                  >
                    <ListItemButton
                      sx={{
                        px: 2
                      }}

                      onClick={() => {
                        menuItem.onClick();
                        if (menuItem?.closeAfterClick) {
                          handleClose();
                        }
                      }}
                    >
                      {menuItem.icon && (
                        <ListItemIcon
                          sx={{
                            minWidth: 24,
                            pr: 2
                          }}
                        >
                          <menuItem.icon
                            sx={(theme) => ({
                              color: menuItem?.color ?? theme.palette.neutral.light,
                              fontSize: theme.typography.pxToRem(24)
                            })}
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        sx={{
                          my: 0.375,
                          ...(menuItem?.color && {
                            color: menuItem.color
                          })
                        }}
                        primary={
                          <Typography variant='body1' lineHeight={1.2}>
                            {menuItem.name}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </MenuItem>
                ))}
                {shouldAddDivider && (
                  <Divider
                    sx={{
                      my: 1
                    }}
                  />
                )}
              </Fragment>
            );
          })}
          {footer}
        </FormControl>
      </Menu>
    </>
  );
};

export default SimpleMenu;
