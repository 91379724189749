import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const FormInputText = (props) => {
  const { name, helperText } = props;
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...props}
          {...field}
          error={invalid}
          helperText={error ? error.message : helperText}
        />
      )}
    />
  );
};

export default FormInputText;
