import { PropsWithChildren, RefObject, createContext, useRef } from 'react';

export const HelperCanvasContext = createContext<RefObject<HTMLCanvasElement> | null>(null);

//* ? Helper canvas used for text measuring, to help us decide should we show tooltip and truncate text or not */

const HelperCanvasProvider = ({ children }: PropsWithChildren) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  return (
    <>
      <canvas id='helperCanvas' hidden ref={canvasRef} />
      <HelperCanvasContext.Provider value={canvasRef}>{children}</HelperCanvasContext.Provider>
    </>
  );
};

export default HelperCanvasProvider;
