import { Grid, Typography } from '@mui/material';
import Image from './image';
import imageNoAddedStops from '../assets/images/no-added-stops';

const EmptyAddressPlaceholder = () => (
  <Grid
    sx={{
      display: 'flex',
      height: 'max-content',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      pt: 15.5
    }}
  >
    <>
      <Image image={imageNoAddedStops} alt='No added stops placeholder' />
      <Typography
        variant='body1'
        sx={(theme) => ({
          pt: theme.typography.pxToRem(16),
          color: theme.palette.neutral[800]
        })}
        component='h5'
      >
        Zoek op adres of postcode
      </Typography>
      <Typography
        sx={{
          pt: 1,
          color: (theme) => theme.palette.neutral[600],
          textAlign: 'center',
          fontSize: (theme) => theme.typography.pxToRem(14),
          maxWidth: 344
        }}
      >
        Na het zoeken zoomt de kaart in op het adres of de postcode. Selecteer een object om
        locatiegegevens toe te voegen of om informatie te bekijken.
      </Typography>
      {/* <Button startIcon={<Add />} variant='contained' sx={{ mt: 4 }} onClick={onClick}>
        Zoek adres
      </Button> */}
    </>
  </Grid>
);

export default EmptyAddressPlaceholder;
