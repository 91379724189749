import { getFocusParams } from '@utils/windowParams';
import { APP_BASE_API_URL } from 'constants/env';
import ky, { Options, SearchParamsOption } from 'ky';

const PELIAS_API_INSTANCE = ky.create({
  prefixUrl: `${APP_BASE_API_URL}/geocoding/`,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  retry: {
    limit: 2
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        request.headers.set('withCredentials', `true`);
      }
    ]
  }
});

const PELIAS_API = {
  autocomplete: <T>(searchParams: SearchParamsOption, options?: Options) => {
    return PELIAS_API_INSTANCE('v1/autocomplete', {
      searchParams,
      ...options
    }).json<T>();
  }
};

export default PELIAS_API;
