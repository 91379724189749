const formatStopLocation = (item: {
  properties?: { label?: string };
  description?: string;
  location?: { address?: { formatted?: string } };
}) => {
  const { properties = null, description: placeDescription = '', location = {} } = item;
  const option = properties?.label || placeDescription || location?.address?.formatted;
  const optionProps = option?.split(',');
  const mainTitle = optionProps?.[0];

  const secondaryTitle = optionProps?.slice(1).join('');

  return {
    mainTitle,
    secondaryTitle,
    isMainTitleSemibold: false
  };
};

export default formatStopLocation;
