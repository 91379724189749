import { APP_STADIA_MAPS_API_KEY } from '@constants';
import { MapLayerType } from 'types/map';

const getMapStyle = (mapLayer: MapLayerType) => {
  return mapLayer === MapLayerType.SATELLITE
    ? `mapbox://styles/mapbox/satellite-streets-v12`
    : `https://tiles-eu.stadiamaps.com/styles/osm_bright.json?api_key=${APP_STADIA_MAPS_API_KEY}`;
};

export default getMapStyle;
