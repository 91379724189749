import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import CypressIds from '../../../cypressIds';

const BackToPreviousPageButton = ({ onClick, menu, sx, ...otherProps }) => (
  <IconButton
    onClick={onClick}
    sx={{
      mr: menu ? 1.5 : 2,
      ...sx
    }}
    {...otherProps}
    data-cy={CypressIds.COMMON_BACK_BUTTON}
  >
    <ArrowBack
      sx={{
        fontSize: (theme) => theme.typography.pxToRem(24)
      }}
    />
  </IconButton>
);

export default BackToPreviousPageButton;
