/* eslint-disable max-lines-per-function */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-unstable-nested-components */

// TODO: Refactor this component, split it into smaller components

import { useEffect, useState } from 'react';
import { Box, Button, Grid, styled } from '@mui/material';
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { getBuildingAddresses } from '@services/buildingService';
import { SectionHeader } from '@components/common/section';
import Details from '@components/list/Details';
import Item from '@components/list/Item';
import NotFoundText from '@components/styled/not-found-text';
import { Virtuoso } from 'react-virtuoso';
import { EditApartmentLocation } from '@components/EditApartmentLocation';
import {
  buildingAddressesAtom,
  buildingPostalCodesAtom,
  locationsAtom,
  mapLayerAtom
} from '@store';
import {
  displayAddressesAndLocations,
  hoverOverLocations,
  restoreAddressesAndLocationsMapState
} from '@utils/map';
import useMapContext from '@hooks/useMapContext';
import useKeyboard from '@hooks/use-keyboard';
import { IBuildingAddress, ILocation } from '@types';
import { Map } from 'maplibre-gl';
import { ListItemType } from '@constants';
import SearchBar from '@components/search-bar';
import formatAddress from '@helpers/formatAddress';
import useSearchHighlighting from '@hooks/use-search-highlighting';
import { debounce } from 'lodash-es';

import BuildingAddressesSectionPlaceholder from './BuildingAddressesSectionPlaceholder';
import CypressIds from '../../../../../cypressIds';

const addressesRequestAbortController = new AbortController();

const addressItemSx = {
  px: 0,
  pb: 0,
  cursor: 'auto'
};

const List: React.FC = styled('div')(() => ({
  display: 'flex',
  gap: 8,
  flexDirection: 'column'
}));

const BuildingAddressesSection = () => {
  const params = useParams();
  const buildingId = params.buildingId as string;
  // Atoms
  const [, setPostalCodes] = useAtom(buildingPostalCodesAtom);
  const [locations] = useAtom(locationsAtom);
  const [searchValue, setSearchValue] = useState('');
  // Context
  const { map } = useMapContext();

  // States
  const [addresses, setAddresses] = useAtom(buildingAddressesAtom);
  const [isFetchingABuildingAddresses, setIsFetchingBuildingAddresses] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentItem, setCurrentItem] = useState<IBuildingAddress | null>(null);
  const [selectedItems, setSelectedItems] = useState<IBuildingAddress[]>([]);
  const isShiftPressed = useKeyboard();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [mapLayer] = useAtom(mapLayerAtom);
  const currentBuildingId = useRef(buildingId);
  const debouncedHoverOverLocations = useRef(debounce(hoverOverLocations, 500)).current;
  const debouncedDisplayAddressesAndLocations = useRef(
    debounce(displayAddressesAndLocations, 500)
  ).current;

  const highlightSearchedValue = useSearchHighlighting();

  useEffect(() => {
    const debouncedGetBuildingAddresses = debounce(() => {
      if (addresses === null) {
        setIsFetchingBuildingAddresses(true);
        setSelectedItems([]);
        getBuildingAddresses<IBuildingAddress[]>(`${buildingId}`, {
          signal: addressesRequestAbortController.signal
        })
          .then((newAddresses: IBuildingAddress[]) => {
            const formattedNewAddresses = newAddresses?.map(({ zipCode, ...addressProps }) => {
              const [zipCodeNumbers, zipCodeLetters] = [zipCode?.slice(0, 4), zipCode?.slice(4)];
              const postalCode = `${zipCodeNumbers || ''} ${zipCodeLetters || ''}`;

              return {
                ...addressProps,
                zipCode: postalCode
              };
            });

            setAddresses(formattedNewAddresses);
            const postalCodes = formattedNewAddresses
              .filter((item) => item?.zipCode.trim())
              .map((address) => {
                return address.zipCode;
              });
            setPostalCodes(Array.from(new Set(postalCodes)));
          })
          .catch(() => {
            setAddresses([]);
            setPostalCodes([]);
          })
          .finally(() => {
            setIsFetchingBuildingAddresses(false);
          });
      }
    }, 100);

    debouncedGetBuildingAddresses();

    return () => {
      debouncedGetBuildingAddresses.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, buildingId, locations, map, mapLayer]);

  useEffect(() => {
    return () => {
      if (currentBuildingId.current !== buildingId) {
        currentBuildingId.current = buildingId;
        restoreAddressesAndLocationsMapState(map);
        addressesRequestAbortController.abort();
        setAddresses(null);
      }

      if (!buildingId) {
        addressesRequestAbortController.abort();
        searchParams.delete('addressId');
      }
      restoreAddressesAndLocationsMapState(map);
    };
  }, [buildingId, map, searchParams, setAddresses]);

  const handleUpdateClick = () => {
    setIsEditModalOpen(true);
  };

  const selectedItemsCount = selectedItems.length;

  useEffect(() => {
    const addressId = searchParams.get('addressId');
    if (addressId && addresses && map) {
      const item = addresses?.find((address) => +address.id === +addressId);

      map?.flyTo({
        center: item?.coordinates
      });
    }
  }, [addresses, map, searchParams]);

  const filteredAddresses = useMemo(() => {
    if (searchValue) {
      return addresses?.filter((address) => {
        const { mainTitle, secondaryTitle } = formatAddress(address);

        const isMainTitleIncludes = mainTitle
          .toLowerCase()
          .includes(searchValue.toLocaleLowerCase());
        const isSecondaryTitleIncludes = secondaryTitle
          .toLowerCase()
          .includes(searchValue.toLocaleLowerCase());

        return isMainTitleIncludes || isSecondaryTitleIncludes ? address : null;
      }) as IBuildingAddress[];
    }

    return addresses as IBuildingAddress[];
  }, [addresses, searchValue]);

  const handleItemClick = useCallback(
    (item: IBuildingAddress) => {
      const finalAddresses = filteredAddresses;
      if (selectedItems.length && finalAddresses) {
        if (isShiftPressed) {
          const lastSelectedItem = selectedItems[selectedItems.length - 1];
          const lastSelectedItemIndex = finalAddresses.findIndex(
            (item) => item.id === lastSelectedItem.id
          );
          const currentItemIndex = finalAddresses.findIndex(
            (addressItem) => addressItem.id === item.id
          );
          const items = finalAddresses.slice(
            Math.min(lastSelectedItemIndex, currentItemIndex),
            Math.max(lastSelectedItemIndex, currentItemIndex) + 1
          );
          setSelectedItems(items);
        } else {
          setSelectedItems((prev: IBuildingAddress[]) =>
            prev.find((currentItem) => currentItem.id === item.id)
              ? prev.filter((currentItem) => currentItem.id !== item.id)
              : [...prev, item]
          );
        }
      } else {
        setSearchParams((prev) => {
          prev.set('addressId', `${item.id}`);
          return prev;
        });
      }
    },
    [filteredAddresses, isShiftPressed, selectedItems, setSearchParams]
  );

  const handleDisplayAddressesAndLocations = useCallback(
    (item: IBuildingAddress | null) => {
      if (item) {
        hoverOverLocations(item.featureIds.map((featureId) => `${featureId}`));
        debouncedDisplayAddressesAndLocations({
          data: [
            {
              buildingId,
              addresses: addresses?.reduce(
                (acc, address) => {
                  if (
                    address.coordinates[0] !== item.coordinates[0] &&
                    address.coordinates[1] !== item.coordinates[1] &&
                    address.id !== item.id
                  ) {
                    acc.push(address);
                  }
                  return acc;
                },
                [item]
              )
            }
          ],
          hoveredItemId: item.id,
          map: map as Map,
          locations: locations as ILocation[]
        });
      } else {
        hoverOverLocations(null);
        debouncedDisplayAddressesAndLocations({
          data: [
            {
              buildingId,
              addresses: addresses as IBuildingAddress[]
            }
          ],
          dontShowSnapToRoadWhenAddressHasAttachedLocations: true,
          locations: locations as ILocation[],
          map: map as Map,
          hoveredItemId: null
        });
      }
    },
    [addresses, buildingId, debouncedDisplayAddressesAndLocations, locations, map]
  );

  useEffect(() => {
    handleDisplayAddressesAndLocations(currentItem);

    return () => {
      debouncedHoverOverLocations.cancel();
      debouncedDisplayAddressesAndLocations.cancel();
    };
  }, [
    currentItem,
    debouncedDisplayAddressesAndLocations,
    debouncedHoverOverLocations,
    handleDisplayAddressesAndLocations,
    map
  ]);

  useEffect(() => {
    return restoreAddressesAndLocationsMapState(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAddress = useCallback(
    (index: number, address: IBuildingAddress) => {
      return (
        <Item
          key={address.id}
          handleCurrentItemChange={setCurrentItem}
          item={address}
          index={index}
          highlightSearchedValue={highlightSearchedValue}
          searchValue={searchValue}
          type={ListItemType.Address}
          currentItem={currentItem}
          handleItemClick={handleItemClick}
          selectedListItems={selectedItems}
          setSelectedItems={setSelectedItems}
          sx={addressItemSx}
        />
      );
    },
    [currentItem, handleItemClick, highlightSearchedValue, searchValue, selectedItems]
  );

  const isLoading = !addresses || isFetchingABuildingAddresses;

  const addressesCount = filteredAddresses?.length;

  return (
    <Grid
      onMouseLeave={() => {
        if (currentItem) {
          setCurrentItem(null);
        }
      }}
    >
      {isLoading ? (
        <BuildingAddressesSectionPlaceholder />
      ) : (
        <>
          <Box width='100%' pb={2}>
            <SearchBar
              sx={{
                px: 0,
                pt: 1
              }}
              label='Zoeken'
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
            />
          </Box>
          <Grid
            container
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {selectedItemsCount > 0 && (
              <>
                <Grid display='flex' alignItems='center'>
                  <Details
                    sx={{
                      ml: 1,
                      pb: 1
                    }}
                    listItems={filteredAddresses}
                    selectedListItems={selectedItems}
                    setSelectedListItems={setSelectedItems}
                  />
                </Grid>
                <Button
                  sx={{
                    '& svg': {
                      fontSize: '1.25rem !important'
                    }
                  }}
                  data-cy={CypressIds.ATTACH_FEATURES_BUTTON}
                  startIcon={<Edit />}
                  onClick={handleUpdateClick}
                  variant='outlined'
                >
                  Bewerken
                </Button>
              </>
            )}
            <Grid display='flex' justifyContent='space-between' container>
              <SectionHeader>Adressen ({addressesCount ?? 0})</SectionHeader>
              {(addressesCount ?? 0) > 0 && (
                <Box
                  onClick={() => setIsExpanded((isExpandedValue) => !isExpandedValue)}
                  component={isExpanded ? ExpandLess : ExpandMore}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    '&': {
                      fontSize: '1.5rem !important'
                    },
                    color: theme.palette.neutral.light
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Grid container flexDirection='column' gap={1} pt={1.5} onMouseLeave={() => {}}>
            {filteredAddresses.length > 0 && (
              <Virtuoso
                style={{ height: 'calc(100vh - 300px)' }}
                height={56}
                overscan={10}
                data={isExpanded ? filteredAddresses : []}
                itemContent={renderAddress}
                components={{
                  List
                }}
              />
            )}

            {filteredAddresses &&
              filteredAddresses?.length === 0 &&
              !isFetchingABuildingAddresses && (
                <NotFoundText pt={2}>Geen adressen gevonden</NotFoundText>
              )}
          </Grid>
          {isEditModalOpen && (
            <EditApartmentLocation
              locations={locations as ILocation[]}
              selectedItems={selectedItems}
              onClose={(refetch?: boolean) => {
                setIsEditModalOpen(false);
                if (refetch) {
                  searchParams.delete('addressId');
                  setAddresses(null);
                  setCurrentItem(null);
                }
              }}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default BuildingAddressesSection;
