/* DON'T EDIT THIS FILE. IT'S AUTOGENERATED. */
/* istanbul ignore file */
import png1dot5x from './images/logo-type@1.5x.png';
import webp1dot5x from './images/logo-type@1.5x.webp';
import png1x from './images/logo-type@1x.png';
import webp1x from './images/logo-type@1x.webp';
import png2x from './images/logo-type@2x.png';
import webp2x from './images/logo-type@2x.webp';
import png3x from './images/logo-type@3x.png';
import webp3x from './images/logo-type@3x.webp';
import png4x from './images/logo-type@4x.png';
import webp4x from './images/logo-type@4x.webp';

const imageLogoType = {
  width: 160,
  height: 32,
  fallback: png1x,
  webpSrcset: `${webp1x} 1x, ${webp1dot5x} 1.5x, ${webp2x} 2x, ${webp3x} 3x, ${webp4x} 4x`,
  pngSrcset: `${png1x} 1x, ${png1dot5x} 1.5x, ${png2x} 2x, ${png3x} 3x, ${png4x} 4x`
};

export default imageLogoType;
