import { outlinedInputClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined'
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...outerTheme.typography.body2,
          lineHeight: 1
        },
        input: {
          height: 20
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...outerTheme.typography.body2,
          lineHeight: 1,
          height: 20
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          color: outerTheme.palette.neutral['600']
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: outerTheme.palette.neutral['600'],
          ...(ownerState.variant === 'outlined' && {
            transform: 'translate(13px, 21px) scale(1)',
            ...(ownerState.shrink && {
              transform: 'translate(14px, -5px) scale(0.8)'
            })
          })
        })
      }
    },
    MuiInputAdornment: {
      defaultProps: {
        disableTypography: true
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...outerTheme.typography.body2,
          lineHeight: 1,
          ...(ownerState.position === 'start' && {
            marginRight: 0
          }),
          ...(ownerState.position === 'end' && {
            marginLeft: 0
          })
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: outerTheme.palette.neutral['600']
          },
          '@media (hover: none)': {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: outerTheme.palette.neutral['600']
            }
          },
          [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: outerTheme.palette.primary.main
          },
          [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: outerTheme.palette.error.main
          },
          [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: outerTheme.palette.action.disabled
          },
          /*
                        Fix for ownerState become undefined.
                        More info: https://github.com/mui/material-ui/issues/31982
                     */
          ...(ownerState &&
            ownerState.startAdornment && {
              paddingLeft: 14
            }),
          ...(ownerState &&
            ownerState.endAdornment && {
              paddingRight: 14
            })
        }),
        input: {
          padding: outerTheme.spacing(2.1254, 1.625)
        },
        notchedOutline: {
          borderColor: outerTheme.palette.neutral['300']
        }
      }
    }
  }
});

const componentTextFieldTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentTextFieldTheme;
