/* eslint-disable @typescript-eslint/no-explicit-any */
import { FmdGood } from '@mui/icons-material';
import { Grid } from '@mui/material';
import LeftSideCell from '../../leftSideCell';
import DefaultItemInfo from './DefaultItem';
import { ListItemType } from '../../../constants';
import formatStopLocation from '../../../helpers/formatStopLocation';
import formatAddress from '../../../helpers/formatAddress';
import CypressIds from '../../../../cypressIds';

const StopItem = ({
  highlightSearchedValue,
  item,
  searchValue,
  currentItem,
  disableCheck,
  selectedListItems,
  setSelectedItems,
  type
}: {
  highlightSearchedValue?: (value: string, searchValue: string) => JSX.Element;
  item: any;
  searchValue?: string;
  currentItem?: any;
  disableCheck?: boolean;
  selectedListItems?: any[];
  setSelectedItems?: any;
  type: ListItemType;
}) => {
  const isCurrentItem = currentItem?.id === item.id;
  const { mainTitle, secondaryTitle } =
    type === ListItemType.Stop ? formatStopLocation(item) : formatAddress(item);

  const canCellBecomeCheckable = !disableCheck && type !== ListItemType.Stop;

  return (
    <Grid
      container
      flexWrap='nowrap'
      alignItems='center'
      gap={2.5}
      data-cy={`${CypressIds.SEARCHED_FEATURE}-${item?.properties?.id}`}
    >
      {/* Cell can become selectable or not it's up to conditions or combination of conditions which will decide is the left side cell can become checkable or not */}
      <LeftSideCell
        defaultIcon={FmdGood}
        canBecomeCheckable={canCellBecomeCheckable}
        isCurrentItem={isCurrentItem}
        item={item}
        selectedListItems={selectedListItems}
        setSelectedItems={setSelectedItems}
      />
      <DefaultItemInfo
        mainTitle={mainTitle ?? ''}
        secondaryTitle={secondaryTitle ?? ''}
        highlightSearchedValue={highlightSearchedValue}
        searchValue={searchValue}
      />
    </Grid>
  );
};

export default StopItem;
