import { Box, Typography } from '@mui/material';

import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import FlexibleHeader from '../flexible-header';
import BackToPreviousPageButton from '../back-to-previous-page-button';
import CloseButton from '../CloseButton';
import CypressIds from '../../../cypressIds';

export const PAGE_HEADER_BUTTON_TYPE = {
  BACK: 'BACK',
  CLOSE: 'CLOSE'
};

interface PageHeaderProps {
  label: string;
  previousPageURL?: string;
  menu?: boolean;
  onClick?: () => void;
  buttonType?: string;
}

const PageHeader = ({ label, previousPageURL, menu, onClick, buttonType }: PageHeaderProps) => {
  const navigate = useAdvancedNavigate();

  return (
    <FlexibleHeader
      menu={menu}
      {...(menu && {
        onClick: () => (onClick ? onClick() : navigate(`${previousPageURL ?? -1}`))
      })}
    >
      <Box
        component={
          buttonType === PAGE_HEADER_BUTTON_TYPE.CLOSE ? CloseButton : BackToPreviousPageButton
        }
        onClick={() => (onClick ? onClick() : navigate(`${previousPageURL ?? -1}`))}
        menu={menu}
        data-cy={CypressIds.COMMON_CLOSE_BUTTON}
        disableRipple={menu}
        sx={{
          ...(menu && {
            ml: 2
          }),
          ml: -1
        }}
      />
      <Typography
        {...(!menu && {
          variant: 'h5'
        })}
        sx={{
          cursor: 'pointer',
          ...(menu && {
            fontWeight: 400,
            fontSize: (theme) => theme.typography.pxToRem(15),
            lineHeight: '1.5rem',
            color: (theme) => theme.palette.neutral.main
          })
        }}
      >
        {label}
      </Typography>
    </FlexibleHeader>
  );
};

export default PageHeader;
