import { Outlet, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { userAuthStatusAtom } from '@store';
import useLayoutLoading from '@hooks/useLayoutLoading';
import { USER_AUTH_STATUS } from '@constants';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';

const Layout = () => {
  const navigate = useAdvancedNavigate();
  const [userAuthStatus] = useAtom(userAuthStatusAtom);
  const location = useLocation();
  useEffect(() => {
    if (userAuthStatus === USER_AUTH_STATUS.FAIL) {
      navigate('/auth/login');
    } else if (
      userAuthStatus === USER_AUTH_STATUS.SUCCESS &&
      location.pathname.includes('/auth/login')
    ) {
      navigate('/');
    }
  }, [location.pathname, navigate, userAuthStatus]);

  useLayoutLoading();

  return <Outlet />;
};

export default Layout;
