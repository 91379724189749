import { IBuildingAddress } from '@types';

export enum LocationType {
  Parking = 'Parking',
  Voordeur = 'Voordeur', // Entrance
  Poort = 'Poort' // Gate
}

export enum LocationTypeEn {
  Parking = 'PARKING',
  Voordeur = 'ENTRANCE',
  Poort = 'GATE'
}

// Create array of LocationType values for use in select dropdowns with id and label
export const LocationTypeOptions = Object.entries(LocationType).map(([id, title]) => ({
  id,
  title
}));

export enum ListItemType {
  Stop = 'Stop',
  Address = 'Address',
  Roster = 'Roster'
}

export type ListItem = IBuildingAddress;

export const TEMPORARY_MARKER_COLOR = '#20212429';

export const SNAP_TO_ROAD_LINE_COLOR = '#606165A3';
export const ADDRESS_MARKER_COLOR = '#455A64';
export const ADDRESS_HOVERED_ITEM_COLOR = '#77B3FF';

export const MAP_SNAP_TO_ROAD_COLOR = '#E4635A';
export const MAP_SNAP_TO_ROAD_HOVER_COLOR = '#4363D8';
export const PARKING_COLOR = '#4575DD';
export const GATE_COLOR = '#FFA500';
export const ENTRANCE_COLOR = '#2EB67D';

export enum USER_AUTH_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

export const COMMON_ERROR_MESSAGE = 'Oeps, er is iets misgegaan!';
