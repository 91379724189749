import { checkboxClasses, tableCellClasses, tableRowClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderWidth: 1,
          borderBottomStyle: 'solid',
          borderColor: outerTheme.palette.neutral[200]
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
          whiteSpace: 'nowrap',
          padding: outerTheme.spacing(1.72, 2),
          [`.${checkboxClasses.root}`]: {
            padding: 0
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderBottom: '0px',
          borderStyle: 'solid',
          borderColor: outerTheme.palette.neutral[200],
          borderRadius: '8px 8px 0 0',
          marginTop: outerTheme.spacing(1)
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: outerTheme.palette.neutral[50],
          [`.${tableRowClasses.root} .${tableCellClasses.root}`]: {
            ...outerTheme.typography.subtitle5,
            lineHeight: 1
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          height: 56,
          [`.${tableRowClasses.root}`]: {
            '&:hover': {
              backgroundColor: outerTheme.palette.neutral['100']
            }
          },
          [`.${tableRowClasses.root}:hover div`]: {
            visibility: 'visible'
          },
          [`.${tableRowClasses.root} .${tableCellClasses.root}`]: {
            ...outerTheme.typography.body3,
            lineHeight: 1
          }
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: 32,
          borderWidth: 1,
          borderTop: '0px',
          borderStyle: 'solid',
          borderColor: outerTheme.palette.neutral[200],
          borderRadius: '0 0 8px 8px'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderTop: '0px',
          borderStyle: 'solid',
          borderColor: outerTheme.palette.neutral[200],
          borderRadius: '0 0 8px 8px'
        }
      }
    }
  }
});

const componentTableTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentTableTheme;
