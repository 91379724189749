import { object, string } from 'yup';

const authValidationSchema = object({
  email: string()
    .required('Email is required')
    .max(255, 'Email is too long')
    .email('Email is not valid'),
  password: string()
    .required('Password is required')
    .min(6, 'Password is too short')
    .max(255, 'Password is too long')
});

export default authValidationSchema;
