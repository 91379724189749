import { Close } from '@mui/icons-material';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { snackbarAtom } from '@store';
import { PropsWithChildren } from 'react';
import CypressIds from '../../cypressIds';

const ToastProvider = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const [snackbarOptions] = useAtom(snackbarAtom);
  return (
    <>
      <Toaster
        containerStyle={{
          left: 32
        }}
        position='bottom-left'
        gutter={2}
      >
        {(toastInstance) => (
          <ToastBar
            toast={toastInstance}
            style={{
              background: '#202124',
              color: '#fff',
              maxWidth: 480,
              ...snackbarOptions
            }}
          >
            {({ message }) => (
              <Grid
                sx={{
                  minWidth: 320,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  ...snackbarOptions
                }}
              >
                <Typography data-cy={CypressIds.TOAST_MESSAGE}>{message}</Typography>
                {toastInstance.type !== 'loading' && (
                  <IconButton
                    sx={{
                      mr: 2,
                      color: theme.palette.neutral['0-56']
                    }}
                    onClick={() => toast.dismiss(toastInstance.id)}
                  >
                    <Close />
                  </IconButton>
                )}
              </Grid>
            )}
          </ToastBar>
        )}
      </Toaster>
      {children}
    </>
  );
};

export default ToastProvider;
