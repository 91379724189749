import { PropsWithChildren } from 'react';
import AuthWrapper from '../wrappers/AuthWrapper';
import HelperCanvasProvider from './HelperCanvas';
import ToastProvider from './ToastProvider';

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <ToastProvider>
      <AuthWrapper>
        <HelperCanvasProvider>{children}</HelperCanvasProvider>
      </AuthWrapper>
    </ToastProvider>
  );
};

export default Providers;
