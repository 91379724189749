import { IBuilding } from '@types';
import { LngLatBounds, Map } from 'maplibre-gl';
import React from 'react';

const MapContext = React.createContext<{
  map: Map | null;
  buildings: IBuilding[] | null;
  bounds: LngLatBounds | undefined;
}>({
  map: null,
  buildings: [],
  bounds: undefined
});

export default MapContext;
