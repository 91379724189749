import { flow } from 'lodash-es';
import { createTheme } from '@mui/material/styles';

import breakpointsTheme from './breakpoints-theme';
import componentBackdropTheme from './component-backdrop-theme';
import componentButtonTheme from './component-button-theme';
import componentDialogTheme from './component-dialog-theme';
import componentFabTheme from './component-fab-theme';
import componentLinkTheme from './component-link-theme';
import componentMenuItemTheme from './component-menu-item-theme';
import componentPopoverTheme from './component-popover-theme';
import componentSelectTheme from './component-select-theme';
import componentSnackbarTheme from './component-snackbar-theme';
import componentSvgIconTheme from './component-svg-icon-theme';
import componentSwitchTheme from './component-switch-theme';
import componentTabTheme from './component-tab-theme';
import componentTableTheme from './component-table-theme';
import componentTabsTheme from './component-tabs-theme';
import componentTextField from './component-textfield-theme';
import componentTooltipTheme from './component-tooltip-theme';
import componentTypographyTheme from './component-typography-theme';
import paletteTheme from './palette-theme';
import shadowsTheme from './shadows-theme';
import transitionsTheme from './transitions-theme';

const waTheme = createTheme({});

export default flow(
  breakpointsTheme,
  paletteTheme,
  shadowsTheme,
  componentTypographyTheme,
  transitionsTheme,
  componentBackdropTheme,
  componentSvgIconTheme,
  componentPopoverTheme,
  componentTextField,
  componentButtonTheme,
  componentLinkTheme,
  componentSwitchTheme,
  componentTooltipTheme,
  componentFabTheme,
  componentSnackbarTheme,
  componentSelectTheme,
  componentMenuItemTheme,
  componentTableTheme,
  componentDialogTheme,
  componentTabsTheme,
  componentTabTheme
)(waTheme);
