import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box } from '@mui/material';

const PasswordVisibility = ({ showPassword }) => (
  <Box
    component={showPassword ? Visibility : VisibilityOff}
    sx={{
      color: (theme) => theme.palette.secondary[100]
    }}
  />
);

export default PasswordVisibility;
