import { useMemo } from 'react';
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast';

function useSnackbar() {
  return useMemo(
    () => ({
      show(
        message: Renderable | ValueFunction<Renderable, Toast>,
        options = {
          style: {
            background: '#202124',
            color: '#fff'
          }
        }
      ) {
        toast(message, options);
      },
      error(
        message: Renderable | ValueFunction<Renderable, Toast>,
        options: Partial<
          Pick<
            Toast,
            | 'position'
            | 'id'
            | 'icon'
            | 'duration'
            | 'ariaProps'
            | 'className'
            | 'style'
            | 'iconTheme'
          >
        > = {
          style: {
            background: '#d32f2f',
            color: '#fff'
          },
          position: 'top-center'
        }
      ) {
        toast.error(message, options);
      }
    }),
    []
  );
}

export default useSnackbar;
