import { useEffect, useState } from 'react';

const useKeyboard = (keyName = 'Shift') => {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === keyName && !isKeyPressed) {
        setIsKeyPressed(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    const handleKeyUp = () => {
      if (isKeyPressed) {
        setIsKeyPressed(false);
      }
    };
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isKeyPressed, keyName]);

  return isKeyPressed;
};

export default useKeyboard;
