import { outlinedInputClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          [`&.${outlinedInputClasses.input}`]: {
            padding: outerTheme.spacing(1.875, 1.625),
            ...outerTheme.typography.body2,
            lineHeight: 1.6
          }
        }
      }
    }
  }
});

const componentSelectTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentSelectTheme;
