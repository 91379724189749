import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    }
  }
};

const componentLinkTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions);

export default componentLinkTheme;
