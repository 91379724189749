import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Grid } from '@mui/material';
import { getBuilding, getBuildingLocations } from '@services/buildingService';
import MapContext from '@contexts/MapContext';

import { BuildingState, setBuildingState } from '@utils/map';
import PageHeader from '@components/PageHeader';
import PageLoading from '@components/common/PageLoading';
import { IBuilding } from '@types';
import { Map } from 'maplibre-gl';
import { buildingAddressesAtom, locationsAtom } from '@store';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import { mergeUniqueItemsArray } from '@utils/mergeUniqueItems';
import { REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS } from 'constants/map';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import BuildingSection from './BuildingSection';
import BuildingAddressesSection from './addresses/BuildingAddressesSection';

const buildingDetailsAbortController = new AbortController();

function centerOfPolygon(points) {
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  for (const point of points) {
    const lat = point[0]; // Assuming latitude is at index 0
    const lng = point[1]; // Assuming longitude is at index 1

    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  }

  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  return centerLat ? [centerLat, centerLng] : points[0];
}

const BuildingDetailPage = () => {
  const { buildingId } = useParams();
  const navigate = useAdvancedNavigate();
  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [, setAddresses] = useAtom(buildingAddressesAtom);
  const changeRouterParams = useChangeRouterSearchParams();
  const [, setLocations] = useAtom(locationsAtom);
  const { map, buildings } = useContext(MapContext);

  useEffect(() => {
    buildings?.forEach((currentBuilding) => {
      if (currentBuilding.id === buildingId) {
        setBuildingState({
          map: map as Map,
          buildings: buildings || [],
          id: `${buildingId}`,
          property: BuildingState.Select,
          state: true
        });
      } else {
        setBuildingState({
          map: map as Map,
          buildings: buildings || [],
          id: currentBuilding.id,
          property: BuildingState.Select,
          state: false
        });
      }
    });

    return () => {
      buildings?.forEach((currentBuilding) => {
        setBuildingState({
          map: map as Map,
          buildings: buildings || [],
          id: currentBuilding.id,
          property: BuildingState.Select,
          state: false
        });
      });
    };
  }, [buildings, map, buildingId]);

  useEffect(() => {
    setBuilding(null);
    if (buildingId) {
      getBuilding(buildingId, {
        signal: buildingDetailsAbortController.signal
      })
        .then((response: unknown) => {
          const buildingData = response as IBuilding;
          if (buildingData == null) {
            navigate(`/`);
            return;
          }
          getBuildingLocations(buildingId).then(({ features }) => {
            setLocations((previousLocations) => {
              return mergeUniqueItemsArray(previousLocations || [], features);
            });
          });

          map?.jumpTo({
            center: centerOfPolygon(buildingData.coordinates),
            ...(map.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS && {
              zoom: REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS
            })
          });
          setAddresses(null);
          setBuilding(buildingData);
        })
        .catch(() => {
          setBuilding({} as IBuilding);
        });
    }

    return () => {
      buildingDetailsAbortController?.abort();
      changeRouterParams('addressId');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, map, navigate, setAddresses, setLocations]);

  useEffect(() => {
    const buildingsSource = map?.getSource('buildings');
    if (!building || !buildingsSource) return;
    setBuildingState({
      map: map as Map,
      buildings: buildings || [],
      id: building.id,
      property: BuildingState.Select,
      state: true
    });
    // eslint-disable-next-line consistent-return
    return () => {
      setBuildingState({
        map: map as Map,
        buildings: buildings || [],
        id: building.id,
        property: BuildingState.Select,
        state: false
      });
    };
  }, [building, buildings, map]);

  if (!building) {
    return <PageLoading />;
  }

  return (
    <>
      <PageHeader previousPageURL='/' label='Gebouwgegevens' />
      <Grid px={3} pb={1.5}>
        <BuildingSection building={building} />
      </Grid>
      <Divider />
      <Grid p={3}>
        <BuildingAddressesSection />
      </Grid>
    </>
  );
};

export default BuildingDetailPage;
