import { IBuildingLocation } from '@types';
import { LocationType, LocationTypeEn } from '../constants';

export const convertType = (type: LocationType | LocationTypeEn) => {
  switch (type) {
    case LocationType.Parking:
      return LocationTypeEn.Parking;
    case LocationType.Poort:
      return LocationTypeEn.Poort;
    case LocationType.Voordeur:
      return LocationTypeEn.Voordeur;
    case LocationTypeEn.Parking:
      return LocationType.Parking;
    case LocationTypeEn.Poort:
      return LocationType.Poort;
    case LocationTypeEn.Voordeur:
      return LocationType.Voordeur;
    default:
      return 'Unknown';
  }
};

const formatLocations = (data: IBuildingLocation[]) =>
  data &&
  Object.values(
    data.reduce(
      (acc, item) => {
        const type = (item.type ? convertType(item.type) : 'Unknown') as LocationType &
          LocationTypeEn;
        if (!acc[type]) {
          acc[type] = [
            {
              ...item,
              index: 1,
              type,
              title: `${type} 1`
            }
          ];
          return acc;
        }
        acc[type].push({
          ...item,
          index: acc[type].length + 1,
          type,
          title: `${type} ${acc[type].length + 1}`
        });
        return acc;
      },
      {} as { [key: string]: IBuildingLocation[] }
    )
  ).flat();

export default formatLocations;
