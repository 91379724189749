enum CypressIds {
  COMMON_GLOBAL_LOADING = 'common-global-loading',
  ADDRESS_INTELLIGENCE_PLATFORM_TITLE = 'address-intelligence-platform-title',
  PASSWORD_INPUT = 'password-input',
  EMAIL_INPUT = 'email-input',
  LOGIN_BUTTON = 'login-button',
  USER_MENU_BUTTON = 'user-menu-button',
  SIGN_OUT_BUTTON = 'sign-out-button',
  MAP_CONTAINER = 'map-container',
  SELECT_MODE_BUTTON = 'select-mode-button',
  MAP_SETTINGS_BUTTON = 'map-settings-button',
  ADD_FEATURES_BUTTON = 'add-features-button',
  ADD_FEATURE_BUTTON = 'add-feature-button',
  EDIT_FEATURE_BUTTON = 'edit-feature-button',
  EDIT_FEATURES_BUTTON = 'edit-features-button',
  DELETE_FEATURE_BUTTON = 'delete-feature-button',
  ATTACH_FEATURES_BUTTON = 'attach-features-button',
  COMMON_CLOSE_BUTTON = 'common-close-button',
  MAP_ZOOM_IN_BUTTON = 'map-zoom-in-button',
  MAP_ZOOM_OUT_BUTTON = 'map-zoom-out-button',
  COMMON_BACK_BUTTON = 'common-back-button',
  ZOOM_INFO = 'zoom-info',
  MAP_LAYER_SETTINGS_BUTTON = 'map-layer-settings-button',
  MAP_LAYER_SETTINGS_DIALOG = 'map-layer-settings-dialog',
  MAP_LAYER_NAME = 'map-layer-name',
  PICK_LOCATION_BUTTON = 'pick-location-button',
  COMMON_CONFIRM_BUTTON = 'common-confirm-button',
  COMMON_CANCEL_BUTTON = 'common-cancel-button',
  LOCATION_TYPE_SELECT = 'location-type-select',
  LOCATION_NAME_INPUT = 'location-name-input',
  TOAST_MESSAGE = 'toast-message',
  SELECT_PARKING = 'select-parking',
  SELECT_GATE = 'select-gate',
  SELECT_ENTRANCE = 'select-entrance',
  COMMON_CHECKBOX = 'common-checkbox',
  ADDRESS_ITEM = 'address-item',
  SEARCH_INPUT = 'search-input',
  SEARCHED_FEATURE = 'searched-feature'
}

export default CypressIds;
