/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShareLocation } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import LeftSideCell from '../../leftSideCell';
import DefaultItemInfo from './DefaultItem';

const RosterItem = ({
  highlightSearchedValue,
  item,
  searchValue,
  currentItem,
  selectedListItems,
  setSelectedItems
}: {
  highlightSearchedValue?: (value: string, searchValue: string) => JSX.Element;
  item: any;
  searchValue?: string;
  currentItem?: any;
  selectedListItems?: any[];
  setSelectedItems?: any;
}) => {
  const isCurrentItem = currentItem?.id === item.id;
  const theme = useTheme();
  const canCellBecomeCheckable = false;

  return (
    <Grid container flexWrap='nowrap' alignItems='center' gap={1.5}>
      {/* Cell can become selectable or not it's up to conditions or combination of conditions which will decide is the left side cell can become checkable or not */}
      <LeftSideCell
        defaultIcon={ShareLocation}
        canBecomeCheckable={canCellBecomeCheckable}
        isCurrentItem={isCurrentItem}
        item={item}
        selectedListItems={selectedListItems}
        setSelectedItems={setSelectedItems}
        color={theme.palette.neutral.main}
      />
      <DefaultItemInfo
        mainTitle={item.title ?? ''}
        highlightSearchedValue={highlightSearchedValue}
        searchValue={searchValue}
      />
    </Grid>
  );
};

export default RosterItem;
