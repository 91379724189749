import { lighten, switchClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: outerTheme.palette.neutral.light,
          [`&.${switchClasses.checked} + .${switchClasses.track}`]: {
            backgroundColor: outerTheme.palette.primary['600-40']
          },
          [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
            opacity: 0.38
          },
          [`&.${switchClasses.disabled} + .${switchClasses.thumb}`]: {
            opacity: 0.38
          },
          [`&.${switchClasses.disabled}`]: {
            color: lighten(outerTheme.palette.neutral.light, 0.62),
            '& > svg': {
              color: lighten(outerTheme.palette.neutral.contrastText, 0.62),
              backgroundColor: lighten(outerTheme.palette.neutral.light, 0.62)
            },
            [`&.${switchClasses.checked} > svg`]: {
              backgroundColor: lighten(outerTheme.palette.primary.main, 0.62)
            }
          },
          '& > svg': {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            boxShadow: outerTheme.shadows[2],
            color: outerTheme.palette.neutral.contrastText,
            background: outerTheme.palette.neutral.light
          },
          [`&.${switchClasses.checked} > svg`]: {
            backgroundColor: outerTheme.palette.primary.main
          }
        },
        track: {
          opacity: 1,
          backgroundColor: outerTheme.palette.neutral['300']
        },
        thumb: {
          boxShadow: outerTheme.shadows[2]
        }
      }
    }
  }
});

const componentSwitchTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentSwitchTheme;
