import { IBuildingAddress } from '@types';

export const formatAddressHouseNumber = (item: IBuildingAddress) => {
  const houseNumberAddition = item.houseNumberAddition ? `-${item.houseNumberAddition}` : '';
  const houseNumber = item.houseNumber || '';
  const houseNumberLetter = item.houseLetter || '';
  return `${houseNumber}${houseNumberLetter}${houseNumberAddition}`;
};

const formatAddress = (item: IBuildingAddress) => {
  const formattedHouseNumber = formatAddressHouseNumber(item);

  return {
    mainTitle: `${item.street || ''} ${formattedHouseNumber}`,
    secondaryTitle: `${item.zipCode || ''} ${item.city || ''}`,
    isMainTitleSemibold: false
  };
};

export default formatAddress;
