import { MAP_INITIAL_ZOOM_LEVEL } from 'constants/map';

// eslint-disable-next-line import/prefer-default-export
export const getFocusParams = () => {
  const params = new URLSearchParams(window.location.search);
  const focusProps = params.get('map')?.split(',');
  const coords = focusProps?.slice(0, 2).map(Number).reverse();
  const zoom = focusProps?.[2] ? Number(focusProps[2]) : MAP_INITIAL_ZOOM_LEVEL;

  return { coords, zoom };
};
