import { useCallback } from 'react';
import useHelperCanvasRef from './use-helper-canvas';

const useCalculateTextWidth = () => {
  const canvasRef = useHelperCanvasRef();

  return useCallback(
    (text: string, fontSize: number) => {
      const context = canvasRef?.getContext('2d');

      if (context) {
        context.font = `${fontSize} Arial`;
        return context.measureText(text).width;
      }

      return 0;
    },
    [canvasRef]
  );
};

export default useCalculateTextWidth;
