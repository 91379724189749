import { APP_VERSION } from '@constants';
import { Button, Grid, Typography } from '@mui/material';

const ErrorFallback = ({ error }: { error: Error | null }) => {
  return (
    <Grid
      display='flex'
      justifyContent='center'
      flexDirection='column'
      width='100vw'
      height='100vh'
      alignItems='center'
    >
      <Typography variant='h5'>
        Something went wrong.
        <Typography
          variant='subtitle1'
          sx={{
            pt: 1,
            fontSize: '1rem',
            fontWeight: 'normal',
            color: 'text.secondary'
          }}
        >
          {error?.message}
        </Typography>
      </Typography>
      <Typography>Version {APP_VERSION}</Typography>
      <Grid my={2}>
        <Typography variant='subtitle2'> Try to reload page or contact support.</Typography>
        <Button
          color='primary'
          sx={{
            maxWidth: 'max-content',
            my: 2
          }}
          variant='outlined'
          onClick={() => window.location.reload()}
        >
          Reload Page
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorFallback;
