import React from 'react';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    subtitle5?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: false;
    h2: false;
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    body3: true;
    body4: true;
    body5: true;
  }
}

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  typography: {
    fontWeightLight: undefined,
    fontWeightBold: undefined,
    h3: {
      fontSize: outerTheme.typography.pxToRem(32),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.1875,
      letterSpacing: '0'
    },
    h4: {
      fontSize: outerTheme.typography.pxToRem(24),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1,
      letterSpacing: '0'
    },
    h5: {
      fontSize: outerTheme.typography.pxToRem(20),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.2,
      letterSpacing: '0'
    },
    h6: {
      fontSize: outerTheme.typography.pxToRem(18),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.2,
      letterSpacing: '0'
    },
    subtitle1: {
      fontSize: outerTheme.typography.pxToRem(16),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.33,
      letterSpacing: '0'
    },
    subtitle2: {
      fontSize: outerTheme.typography.pxToRem(15),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.385,
      letterSpacing: '0'
    },
    subtitle3: {
      fontSize: outerTheme.typography.pxToRem(14),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.25,
      letterSpacing: '0'
    },
    subtitle4: {
      fontSize: outerTheme.typography.pxToRem(13),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.25,
      letterSpacing: '0'
    },
    subtitle5: {
      fontSize: outerTheme.typography.pxToRem(12),
      fontWeight: outerTheme.typography.fontWeightMedium,
      lineHeight: 1.25,
      letterSpacing: '0'
    },
    body1: {
      fontSize: outerTheme.typography.pxToRem(16),
      fontWeight: outerTheme.typography.fontWeightRegular,
      lineHeight: 1.25,
      letterSpacing: '0'
    },
    body2: {
      fontSize: outerTheme.typography.pxToRem(15),
      fontWeight: outerTheme.typography.fontWeightRegular,
      lineHeight: 1.2,
      letterSpacing: '0'
    },
    body3: {
      fontSize: outerTheme.typography.pxToRem(14),
      fontWeight: outerTheme.typography.fontWeightRegular,
      lineHeight: 1.25,
      letterSpacing: '0'
    },
    body4: {
      fontSize: outerTheme.typography.pxToRem(13),
      fontWeight: outerTheme.typography.fontWeightRegular,
      lineHeight: 1.385,
      letterSpacing: '0'
    },
    body5: {
      fontSize: outerTheme.typography.pxToRem(12),
      fontWeight: outerTheme.typography.fontWeightRegular,
      lineHeight: 1.35,
      letterSpacing: '0'
    },
    button: {
      fontSize: outerTheme.typography.pxToRem(15),
      fontWeight: outerTheme.typography.fontWeightMedium,
      textTransform: 'none',
      lineHeight: 1
    },
    caption: {
      fontSize: outerTheme.typography.pxToRem(12),
      fontWeight: outerTheme.typography.fontWeightRegular,
      lineHeight: 1.3334,
      letterSpacing: '0.033333em'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          subtitle3: 'div',
          subtitle4: 'div',
          subtitle5: 'div',
          body1: 'div',
          body2: 'div',
          body3: 'div',
          body4: 'div',
          body5: 'div',
          caption: 'div',
          inherit: 'div'
        }
      }
    }
  }
});

const componentTypographyTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentTypographyTheme;
