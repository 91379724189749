import { APP_BASE_API_URL } from 'constants/env';
import ky from 'ky';

const BASE_API = ky.create({
  prefixUrl: APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  retry: {
    limit: 2
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        if (!request.url.includes('login') || !request.url.includes('logout')) {
          request.headers.set('withCredentials', `true`);
        }
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          window.dispatchEvent(new Event('unauthorized'));
        }
      }
    ]
  }
});

export default BASE_API;
