import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 698,
      md: 954,
      lg: 1200,
      xl: 1536
    }
  }
};

const breakpointsTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions);

export default breakpointsTheme;
