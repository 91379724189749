import { Grid, SxProps } from '@mui/material';
import InputSearch from '@components/common/input-search';

const SearchBar = ({
  value,
  onChange,
  label,
  sx
}: {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  sx?: SxProps;
}) => {
  const searchPlaceholder = label ?? 'Zoek postcodegebied';

  const onSearchClose = () => {
    onChange('');
  };

  return (
    <Grid px={2} py={1} sx={sx}>
      <InputSearch
        onSearchChange={(e: { target: { value: string } }) => {
          onChange(e.target.value);
        }}
        placeholder={searchPlaceholder || 'Search'}
        withSearchIcon
        onSearchClose={onSearchClose}
        onKeyDown={(e: {
          key: string;
          target: { blur: () => void };
          preventDefault: () => void;
        }) => {
          if (e.key === 'Escape') {
            e.target.blur();
            e.preventDefault();
            onSearchClose();
          }
        }}
        sx={{
          width: '100%'
        }}
        searchValue={value}
        shouldHaveEasyAccessShortcut={false}
        isFocused={false}
      />
    </Grid>
  );
};

export default SearchBar;
