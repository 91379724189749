/* eslint-disable max-lines-per-function */
/* eslint-disable sonarjs/cognitive-complexity */
import { Outlet } from 'react-router-dom';
import maplibregl, {
  Map,
  MapGeoJSONFeature,
  MapMouseEvent,
  RequestTransformFunction
} from 'maplibre-gl';
import { useAtom } from 'jotai';
import { Box, Grid, Typography } from '@mui/material';
import MapContext from 'contexts/MapContext';
import {
  displayAddressesAndLocations,
  addBuildings,
  addDepotMarkers,
  shouldShowMapHouseNumbers,
  restoreAddressesAndLocationsMapState,
  initializeMapCustomIcons,
  transformRequest,
  getMapBbox,
  clearAllMarkers,
  addLocationMarkers,
  setBuildingState,
  jumpToLocation,
  BuildingState
} from '@utils/map';
import {
  getBuildingsAddresses,
  getBuildingsWithIn,
  getDepots,
  getLocations
} from '@services/buildingService';
import {
  depotsAtom,
  userAuthStatusAtom,
  mapSettingsAtom,
  selectedBuildingsAtom,
  isBuildingSelectModeAtom,
  isFetchingDepotsAtom,
  mapLayerAtom,
  locationsAtom,
  isLocationsLoadingAtom,
  buildingsWithAddressesAtom,
  userAtom
} from '@store';
import useLayoutLoading from '@hooks/useLayoutLoading';
import PageLoading from '@components/common/PageLoading';
import { USER_AUTH_STATUS } from '@constants';
import { IBuilding, IBuildingAddressesData, IDepot } from '@types';
import {
  MAP_INITIAL_ZOOM_LEVEL,
  REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS
} from 'constants/map';
import { mapCustomIcons } from '@utils/map/data';
import { MapLayerType } from 'types/map';
import getMapStyle from '@utils/map/style';

import { debounce } from 'lodash-es';
import useSnackbar from '@hooks/use-snackbar';
import MapContainer, { ContextMenu } from '@components/map/Container';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import LocationsSection from '@components/LocationsSection';
import { getFocusParams } from '@utils/windowParams';
import { mergeUniqueItemsArray } from '@utils/mergeUniqueItems';
import MapDataFetchCallbackContext from '@contexts/FetchMapDataContext';
import SelectBuilding from '@components/styled/select-building';
import AdvancedIconButton from '@components/advanced-icon-button';
import { Close } from '@mui/icons-material';
import { AddLocationsButton } from '@components/common/AddLocationBtn';
import { EditLocationsButton } from '@components/common/EditLocationBtn';
import CypressIds from '../../../cypressIds';

const buildingsWithInAbortController = new AbortController();
const addressesAbortController = new AbortController();
const locationsAbortController = new AbortController();
const depotsAbortController = new AbortController();

const DEFAULT_MAP_CENTER_COORDINATES = [4.489241728373912, 51.92231369778452];
const MAIN_PAGE_PATH = '/';
// TODO: Refactor this one! to make more smaller.
const Layout = () => {
  const params = new URLSearchParams(window.location.search);
  const navigate = useAdvancedNavigate();
  const mapContainer = useRef(null);
  const snackbar = useSnackbar();
  const map = useRef<Map | null>(null);
  const changeRouterParams = useChangeRouterSearchParams();
  const [locations, setLocations] = useAtom(locationsAtom);
  const [isLocationsLoading, setIsLocationsLoading] = useAtom(isLocationsLoadingAtom);
  const [buildings, setBuildings] = useState<IBuilding[] | null>(null);
  const [depots, setDepots] = useAtom(depotsAtom);
  const [isFetchingDepots, setIsFetchingDepots] = useAtom(isFetchingDepotsAtom);
  const isMapZoomLevelWarningShowed = useRef(false);
  const [mapWrapper, setMapWrapper] = useState<Map | null>(null);
  const rightDrawerRef = useRef(null);
  const [user] = useAtom(userAtom);
  const [userAuthStatus] = useAtom(userAuthStatusAtom);
  const [mapSettings] = useAtom(mapSettingsAtom);
  const shouldShowSnapToRoad = mapSettings?.shouldShowSnapToRoad;
  const shouldShowLocations = mapSettings?.shouldShowLocations;
  const [selectedBuildings, setSelectedBuildings] = useAtom(selectedBuildingsAtom);
  const [isBuildingSelectMode, setIsBuildingSelectMode] = useAtom(isBuildingSelectModeAtom);
  const [, setBuildingsWithAddresses] = useAtom(buildingsWithAddressesAtom);
  const [shouldRefetchAddresses, setShouldRefetchAddresses] = useState(false);
  const selectedBuildingsFromWindow = params.get('selectedBuildings')?.split(',');
  const { buildingId } = useParams();
  const [mapLayer] = useAtom(mapLayerAtom);
  const isMainPage = window.location.pathname === MAIN_PAGE_PATH;
  const debouncedDisplayAddressesAndLocations = useRef(
    debounce(displayAddressesAndLocations, 100)
  ).current;

  const isNotLocationPage = !window.location.pathname.includes('locations');

  const handleMapClick = useCallback(
    (selectedFeature: IBuilding) => {
      const selectedBuildingId = selectedFeature?.properties?.id;

      if (selectedBuildingId && !window.location.pathname.includes('locations')) {
        navigate(`/buildings/${selectedBuildingId}/`);
      }
    },
    [navigate]
  );

  const resetBuildingsSelectMode = useCallback(() => {
    setIsBuildingSelectMode(false);
    setSelectedBuildings([]);
    changeRouterParams('selectedBuildings');
    navigate(MAIN_PAGE_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { coords } = getFocusParams();
    if (!depots && !isFetchingDepots && user?.depotId) {
      setIsFetchingDepots(true);
      getDepots<IDepot[]>({
        signal: depotsAbortController.signal
      })
        .then((newDepots: IDepot[]) => {
          // eslint-disable-next-line eqeqeq
          const userDepot = newDepots.find((newDepot) => newDepot.id == +user.depotId);
          const depotAddresses = userDepot ?? newDepots;
          const shouldGoToDepot = userDepot && isMainPage && userDepot.coordinates && !coords;

          if (shouldGoToDepot) {
            map?.current?.jumpTo({
              center: userDepot.coordinates
            });
          }
          addDepotMarkers(depotAddresses, map.current as Map);
          setDepots(newDepots);
        })
        .catch(() => {
          setDepots([]);
        })
        .finally(() => {
          setIsFetchingDepots(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depots, isMainPage, user?.depotId]);

  useEffect(() => {
    return () => {
      depotsAbortController.abort('no');
      addressesAbortController.abort();
      buildingsWithInAbortController.abort();
      locationsAbortController.abort();
      setIsFetchingDepots(false);
      setDepots(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map.current || buildingId || isLocationsLoading) {
      return;
    }

    const mapZoomLevel = map?.current?.getZoom() ?? 0;
    const isAllowedZoomLevelForAddresses =
      mapZoomLevel >= REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS;
    const shouldClearAddressesAndLocations = isMainPage && !isAllowedZoomLevelForAddresses;

    const shouldShowAddressesAndLocations =
      (buildings &&
        isAllowedZoomLevelForAddresses &&
        (isMainPage || window.location.pathname.includes('locations')) &&
        (shouldShowLocations || shouldShowSnapToRoad)) ||
      shouldRefetchAddresses;

    if (shouldClearAddressesAndLocations) {
      restoreAddressesAndLocationsMapState(map.current);
      shouldShowMapHouseNumbers(map.current, true);
    } else if (shouldShowAddressesAndLocations) {
      shouldShowMapHouseNumbers(map.current, false);
      addressesAbortController.abort();
      getBuildingsAddresses({
        json: {
          buildingIds: buildings.map((building) => building.id) as unknown as string
        },
        signal: addressesAbortController.signal
      })
        .then((data) => {
          setBuildingsWithAddresses(data);
          debouncedDisplayAddressesAndLocations({
            data: data as IBuildingAddressesData[],
            map: map.current as Map,
            locations,
            dontShowSnapToRoadWhenAddressHasAttachedLocations: true,
            enableLocations: shouldShowLocations,
            enableSnapToRoad: shouldShowSnapToRoad
          });
        })
        .catch((e) => {
          snackbar.error('Oops something went wrong');
        })
        .finally(() => {
          if (shouldRefetchAddresses) {
            setShouldRefetchAddresses(false);
          }
        });
    }

    if (!shouldShowLocations && !shouldShowSnapToRoad && isMainPage) {
      restoreAddressesAndLocationsMapState(map.current);
      shouldShowMapHouseNumbers(map.current, true);
    }

    return () => {
      debouncedDisplayAddressesAndLocations.cancel();
      shouldShowMapHouseNumbers(map.current as Map, true);
      addressesAbortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldShowSnapToRoad,
    isMainPage,
    buildingId,
    shouldShowLocations,
    debouncedDisplayAddressesAndLocations,
    buildings,
    locations,
    isLocationsLoading,
    shouldRefetchAddresses
  ]);

  const handleBuildingsFetch = useCallback(async () => {
    const bbox = getMapBbox(map.current);
    let newBuildings = [];
    buildingsWithInAbortController.abort();

    if (map?.current?.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS) {
      buildingsWithInAbortController.abort();
      locationsAbortController.abort();
      return;
    }

    try {
      newBuildings = await getBuildingsWithIn<IBuilding[]>(bbox, {
        signal: buildingsWithInAbortController.signal
      });
      setBuildings(newBuildings);
    } catch (error) {
      snackbar.error('Oops something went wrong');
    } finally {
      try {
        locationsAbortController.abort();
        setIsLocationsLoading(true);
        const { features } = await getLocations(bbox, {
          signal: buildingsWithInAbortController.signal
        });
        setLocations((previousLocations) =>
          window.location.pathname.includes('buildings') &&
          !window.location.pathname.includes('locations')
            ? mergeUniqueItemsArray(previousLocations || [], features)
            : features
        );
      } finally {
        setIsLocationsLoading(false);
        addBuildings(newBuildings, map.current as Map);
      }
    }
  }, [setBuildings, snackbar, setIsLocationsLoading, setLocations]);

  const handleMapMove = useCallback((): void => {
    if (!map.current) return;

    changeRouterParams(
      'map',
      `${map.current
        .getCenter()
        .toArray()
        .reverse()
        .map((value) => value?.toFixed(7))},${map.current.getZoom().toFixed(1)}`
    );

    const mapZoomLevel = map?.current?.getZoom() ?? 0;

    if (mapZoomLevel < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS && map?.current) {
      console.warn('skip to load building because zoom level ', mapZoomLevel);
      if (!isMapZoomLevelWarningShowed.current) {
        snackbar.show(
          `Verhoog het zoomniveau minimaal naar ${REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS} om gebouwen te zien`
        );
        isMapZoomLevelWarningShowed.current = true;
      }
      const resetMapLocationsAndAddresses = () => {
        if (isMainPage) {
          shouldShowMapHouseNumbers(map.current as Map, true);
          restoreAddressesAndLocationsMapState(map.current);
          resetBuildingsSelectMode();
        }
      };
      clearAllMarkers();
      addBuildings([], map?.current);
      setLocations([]);
      resetMapLocationsAndAddresses();
    } else {
      if (isMapZoomLevelWarningShowed.current) {
        isMapZoomLevelWarningShowed.current = false;
      }
      handleBuildingsFetch();
    }
  }, [
    changeRouterParams,
    handleBuildingsFetch,
    isMainPage,
    resetBuildingsSelectMode,
    setLocations,
    snackbar
  ]);

  useEffect(() => {
    // Do it once.
    if (map.current) {
      return;
    }

    const { coords, zoom } = getFocusParams();
    // Take  current coordinates

    const [lng, lat] = coords?.filter(Boolean) ? coords : DEFAULT_MAP_CENTER_COORDINATES;

    const styleURL = getMapStyle(mapLayer as unknown as MapLayerType);
    // Take zoom level from the url
    const zoomInitialLevel = zoom ?? MAP_INITIAL_ZOOM_LEVEL;

    map.current = new maplibregl.Map({
      container: mapContainer.current as unknown as HTMLElement,
      style: styleURL,
      center: [lng, lat],
      zoom: +zoomInitialLevel,
      customAttribution: '© Routetitan',
      transformRequest: transformRequest as RequestTransformFunction
    });

    map?.current?.addControl(
      new maplibregl.NavigationControl({
        showZoom: false,
        visualizePitch: true,
        showCompass: true
      }),
      'bottom-right'
    );

    initializeMapCustomIcons(map.current, mapCustomIcons);

    const compass = map?.current?.getContainer().querySelector('.maplibregl-ctrl-compass');
    const compassButton = compass?.querySelector('.maplibregl-ctrl-icon');

    if (compass) {
      compass.removeAttribute('title');
      compassButton?.addEventListener('mousedown', (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      });
    }

    map.current.on('moveend', handleMapMove);
    map.current.on('zoomend', () => {
      changeRouterParams(
        'map',
        `${map?.current
          ?.getCenter()
          .toArray()
          .reverse()
          .map((value) => value?.toFixed(7))},${map?.current?.getZoom().toFixed(1)}`
      );
      const mapZoomLevel = map?.current?.getZoom() ?? 0;
      if (mapZoomLevel < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS && map?.current) {
        console.warn('skip to load building because zoom level ', mapZoomLevel);
        navigate(MAIN_PAGE_PATH);
        // Delete feat params if it's on location edit
        changeRouterParams('feat');
        if (!isMapZoomLevelWarningShowed.current) {
          snackbar.show(
            `Zoom minimaal in naar niveau ${REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS} om gedetailleerde locatie informatie te zien.`
          );
          isMapZoomLevelWarningShowed.current = true;
        }
        const resetMapLocationsAndAddresses = () => {
          if (isMainPage) {
            shouldShowMapHouseNumbers(map.current as Map, true);
            restoreAddressesAndLocationsMapState(map.current);
          }
        };
        clearAllMarkers();
        addBuildings([], map?.current);
        setLocations([]);
        resetMapLocationsAndAddresses();
      }
    });
    map.current.once('load', handleMapMove);

    setMapWrapper(map.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMapClick, handleMapMove, shouldShowSnapToRoad, isMainPage, mapLayer]);

  useEffect(() => {
    // Map clean up
    return () => {
      map.current?.remove();
      map.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapBuildingClick = useCallback(
    (e: MapMouseEvent & { features?: MapGeoJSONFeature[] }) => {
      const isLocationAdding = map?.current?.getCanvas().style.cursor === 'crosshair';
      if (!isLocationAdding) {
        // Select mode
        if (isBuildingSelectMode) {
          setSelectedBuildings((prev: string[] | null): string[] | null => {
            const currentBuildingId = e?.features?.[0].properties.id as string;
            if (!prev) {
              return [currentBuildingId];
            }

            const isBuildingSelected = prev.includes(currentBuildingId);
            if (isBuildingSelected) {
              return prev.filter((buildingId) => buildingId !== currentBuildingId);
            }
            if (prev.length >= 20) {
              snackbar.show('Je kunt een maximum van 20 gebouwen selecteren');
              return prev;
            }

            return [...prev, currentBuildingId];
          });
        }
        // Go to building details
        else {
          resetBuildingsSelectMode();
          if (e.features) {
            handleMapClick(e.features[0] as unknown as IBuilding);
          }
        }
      }
    },
    [handleMapClick, isBuildingSelectMode, resetBuildingsSelectMode, setSelectedBuildings, snackbar]
  );

  useEffect(() => {
    if (!map.current) {
      return;
    }

    map.current.on('click', 'buildings-layer', handleMapBuildingClick);

    if (!isBuildingSelectMode) {
      setSelectedBuildings(null);
    }

    return () => {
      map.current?.off('click', 'buildings-layer', handleMapBuildingClick);
    };
  }, [
    handleMapBuildingClick,
    handleMapClick,
    isBuildingSelectMode,
    isMainPage,
    setSelectedBuildings
  ]);

  useEffect(() => {
    if (buildings?.length > 0 && map?.current?.isStyleLoaded()) {
      buildings?.forEach((building) => {
        const isBuildingSelected = selectedBuildings?.includes(`${building.id}`);
        if (isBuildingSelected) {
          setBuildingState({
            map: map.current as Map,
            buildings: buildings || [],
            id: `${building.id}`,
            property: BuildingState.MultiSelect,
            state: true
          });
        } else {
          setBuildingState({
            map: map.current as Map,
            buildings: buildings || [],
            id: `${building.id}`,
            property: BuildingState.MultiSelect,
            state: false
          });
        }
      });
    }
  }, [buildings, selectedBuildings, selectedBuildingsFromWindow]);

  useEffect(() => {
    if (selectedBuildingsFromWindow && !selectedBuildings) {
      setSelectedBuildings(selectedBuildingsFromWindow.map((item) => item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildings, selectedBuildingsFromWindow]);

  const mapContextValues = useMemo(
    () => ({
      map: map.current,
      buildings,
      bounds: map.current?.getBounds()
    }),
    [buildings]
  );
  useEffect(() => {
    if (userAuthStatus === USER_AUTH_STATUS.FAIL) {
      navigate('/auth/login');
    }
  }, [navigate, userAuthStatus]);

  useLayoutLoading();

  const onClickCallBack = useCallback((location) => {
    const { promise, resolve } = Promise.withResolvers();
    Promise.resolve().then(() => {
      changeRouterParams(
        'feat',
        location.geometry.coordinates
          .map((item) => item.toFixed(7))
          .join(',')
          .concat(`,${location.properties.name}`)
          .concat(`,${location.properties.type}`)
      );
      resolve();
    });
    jumpToLocation(location, map.current);
    promise.then(() => {
      navigate(`/buildings/locations/${location.id}/update/`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map.current) {
      const shouldAddLocationMarkers = locations && locations?.length > 0 && isNotLocationPage;
      if (shouldAddLocationMarkers && !window.location.pathname.includes('locations')) {
        addLocationMarkers(locations, map.current as Map, '', onClickCallBack);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotLocationPage, onClickCallBack, locations, map]);

  return (
    <Grid
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex'
      }}
    >
      <MapContext.Provider value={mapContextValues}>
        <MapDataFetchCallbackContext.Provider value={handleBuildingsFetch}>
          <Box
            sx={{
              height: '100vh',
              overflowY: 'auto',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              minWidth: 448,
              width: 448,
              minHeight: '70vh',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
            }}
            ref={rightDrawerRef}
          >
            {!mapWrapper || isFetchingDepots ? <PageLoading /> : <Outlet />}
          </Box>
          <Box
            sx={{
              maxHeight: 448,
              display: 'flex',
              flexDirection: 'column',
              width: 448,
              py: 2,
              ml: 2,
              borderRadius: 2,
              zIndex: 999,
              height: 'max-content'
            }}
          >
            <LocationsSection isVisible={isNotLocationPage} />
          </Box>
          <MapContainer mapContainerRef={mapContainer} />
          <ContextMenu rightDrawerRef={rightDrawerRef.current} />
          {(selectedBuildings?.length ?? 0) > 0 && isBuildingSelectMode && (
            <SelectBuilding>
              <Grid
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                px={2}
                py={2}
                sx={{
                  background: 'white',
                  width: 'auto',
                  borderRadius: 1.5,
                  gap: 4
                }}
              >
                <Grid display='flex' alignItems='center' gap={3}>
                  <AdvancedIconButton
                    onClick={resetBuildingsSelectMode}
                    tooltip='Sluit de selectiemodus'
                    icon={<Close />}
                    data-cy={CypressIds.COMMON_CLOSE_BUTTON}
                  />
                  <Typography component='h3'>
                    {selectedBuildings?.length} gebouwen geselecteerd
                  </Typography>
                </Grid>
                <Grid display='flex' alignItems='center' gap={1.5}>
                  <EditLocationsButton
                    onSuccess={() => {
                      resetBuildingsSelectMode();
                      setShouldRefetchAddresses(true);
                    }}
                  />
                  <AddLocationsButton selectedBuildings={selectedBuildings as string[]} />
                </Grid>
              </Grid>
            </SelectBuilding>
          )}
        </MapDataFetchCallbackContext.Provider>
      </MapContext.Provider>
    </Grid>
  );
};

export default Layout;
