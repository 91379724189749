import { Divider, Grid, Stack, SxProps } from '@mui/material';

const FlexibleHeader = ({
  children,
  menu,
  sx,
  onClick
}: {
  children: React.ReactNode;
  menu?: boolean;
  sx?: SxProps;
  onClick?: () => void;
}) => (
  <>
    <Stack
      sx={{
        minHeight: menu ? 56 : 80,
        display: 'flex',
        alignItems: 'center',
        ...(!menu && {
          px: 3
        }),
        ...sx
      }}
      direction='row'
    >
      <Grid
        display='flex'
        onClick={onClick}
        alignItems='center'
        my={2}
        sx={{
          ...(onClick && {
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: (theme) => theme.palette.neutral['100']
            }
          }),
          width: '100%'
        }}
      >
        {children}
      </Grid>
    </Stack>
    <Divider />
  </>
);

export default FlexibleHeader;
