import snapToRoadIcon from 'assets/svg/snap-to-road.svg';
import addressIcon from 'assets/svg/circle.svg';
import { MapLayerType } from 'types/map';

export enum MapSourceName {
  SnapToRoadIcon = 'snapToRoadIcon',
  AddressIcon = 'addressIcon',
  LocationIcon = 'locationIcon',
  SnapToRoadLine = 'snapToRoadLine',
  LocationLine = 'locationLine',
  DepotIcon = 'depotIcon'
}

export const mapCustomIcons = [
  {
    name: MapSourceName.SnapToRoadIcon,
    url: snapToRoadIcon
  },
  {
    name: MapSourceName.AddressIcon,
    url: addressIcon
  }
];

export const getMapLayerInitialState: () => MapLayerType = () => {
  const mapLayer = localStorage.getItem('mapLayer') as MapLayerType;
  return mapLayer || MapLayerType.DEFAULT;
};
