import { IUser } from '@types';
import BASE_API from './httpService';

interface LoginBody {
  hub?: string;
  token?: string;
  username?: string;
  password?: string;
}

const login = (body: LoginBody) => {
    const loginParams = new URLSearchParams();
    Object.entries(body).forEach(([key, value]) => {
        loginParams.append(key, value as string);
    });

  return BASE_API.post(`login`, {
    body: loginParams,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const logout = () => {
  return BASE_API.post(`logout`);
};

const getUser = (): Promise<IUser> => {
  return BASE_API.get(`users/me`).json<IUser>();
};

export default {
  login,
  logout,
  getUser
};
