import CircularLoading from '@components/ui-kit/CircularLoading'
import { USER_AUTH_STATUS } from '@constants';
import { userAuthStatusAtom } from '@store';


const SSO = () => {
  const [userAuthStatus] = useAtom(userAuthStatusAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (USER_AUTH_STATUS.SUCCESS === userAuthStatus) {
      navigate('/');
    } else if(USER_AUTH_STATUS.FAIL === userAuthStatus) {
        navigate('/auth/login');
    }
  }, [userAuthStatus]);

  return (
    <CircularLoading />
  )
}

export default SSO;
