import { isBuildingSelectModeAtom } from '@store';
import { Tooltip } from '@mui/material';
import IconHighlightAll from '@assets/icons/highlight-all';
import { MapControlButton } from '../styled';
import CypressIds from '../../../../cypressIds';

const SelectBuildings = () => {
  const [isBuildingSelectMode, setIsBuildingSelectMode] = useAtom(isBuildingSelectModeAtom);
  const { buildingId } = useParams();
  const handleClick = () => {
    setIsBuildingSelectMode(!isBuildingSelectMode);
  };

  return (
    <Tooltip title='Gebouwen selecteren' placement='bottom'>
      <MapControlButton
        disabled={!!buildingId}
        onClick={handleClick}
        sx={{
          color: (theme) => (isBuildingSelectMode ? theme.palette.primary.main : '')
        }}
        data-cy={CypressIds.SELECT_MODE_BUTTON}
      >
        <IconHighlightAll />
      </MapControlButton>
    </Tooltip>
  );
};

export default SelectBuildings;
