import { useController, useFormContext } from 'react-hook-form';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import Switch from '../switch';

interface FormSwitchProps {
  name: string;
  disabled?: boolean;
}

interface ControlledSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export interface InputSwitchProps
  extends Partial<Omit<FormControlLabelProps, 'onChange'>>,
    Partial<ControlledSwitchProps> {
  label: string;
  helperText?: string;
  value?: boolean;
  disableSwitch?: boolean;
  onChange?: (value: boolean) => void;
}

export const FormSwitch = ({ name, disabled }: FormSwitchProps) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control
  });

  return (
    <Switch
      checked={!!field.value}
      disabled={disabled}
      onChange={(_event: unknown, checked: boolean) => {
        field.onChange(checked);
      }}
    />
  );
};

export const ControlledSwitch: React.FC<ControlledSwitchProps> = ({ value, onChange }) => {
  return (
    <Switch
      checked={value}
      onChange={(_event: unknown, checked: boolean) => {
        onChange(checked);
      }}
    />
  );
};

const InputSwitch = ({
  name,
  label,
  value,
  onChange,
  disableSwitch,
  ...props
}: InputSwitchProps) => (
  <FormControlLabel
    label={label}
    control={
      name ? (
        <FormSwitch name={`${name}`} disabled={disableSwitch} />
      ) : (
        <ControlledSwitch value={value as boolean} onChange={onChange as () => void} />
      )
    }
    {...props}
  />
);

export default InputSwitch;
