import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        scroll: 'paper'
      },
      styleOverrides: {
        paper: {
          borderRadius: 8,
          maxWidth: 448
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: 24,
          padding: outerTheme.spacing(2, 3),
          borderWidth: 1,
          borderColor: outerTheme.palette.divider,
          borderBottomStyle: 'none'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...outerTheme.typography.body3
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: outerTheme.spacing(2, 1.5),
          borderWidth: 1,
          borderColor: outerTheme.palette.divider,
          borderTopStyle: 'none'
        }
      }
    }
  }
});

const componentDialogTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentDialogTheme;
