import { Button, Grid, Stack, styled } from '@mui/material';

export const MapControlContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
  borderRadius: '6px'
}));

export const MapControlButton = styled(Button)(({ theme }) => ({
  width: 40,
  height: 40,
  minWidth: 20,
  color: theme.palette.neutral.light
}));

export const MapInfoItem = styled(Grid)(({ theme }) => ({
  width: 40,
  height: 40,
  minWidth: 20,
  color: theme.palette.neutral.light
}));

export const MapZoomControls = styled(Stack)({
  position: 'absolute',
  right: 20,
  bottom: 50
});

export const MapSettingControlsWrapper = styled(Stack)({
  position: 'absolute',
  right: 20,
  top: 20
});

export const MapLayerSettingsWrapper = styled(Stack)({
  position: 'absolute',
  left: 468,
  bottom: 20
});
