import { Grid, styled } from '@mui/material';

const SelectBuilding = styled(Grid)({
  position: 'absolute',
  height: 64,
  borderRadius: 1.5,
  bottom: 20,
  width: 'calc(100% - 448px)',
  marginLeft: '448px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export default SelectBuilding;
