import { IconButton, styled } from '@mui/material';
import { ICON_LIGHT } from '../../constants';

const ToolbarIcon = styled(IconButton)(() => ({
  '&.Mui-disabled': {
    opacity: 0.5
  },
  '& svg': {
    color: ICON_LIGHT,
    fontSize: '1.5rem'
  }
}));

export default ToolbarIcon;
