import { Menu, Stack, styled, Typography } from '@mui/material';

import { useAtom } from 'jotai';
import InputSwitch, { InputSwitchProps } from '@components/common/input-switch';
import { mapSettingsAtom } from '@store';
import { REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS } from 'constants/map';
import useMapContext from '@hooks/useMapContext';

const FullWidthInputSwitch = styled(InputSwitch, {
  shouldForwardProp: (propName) => propName !== 'helperText'
})({
  justifyContent: 'space-between',
  paddingTop: 1.5,
  paddingBottom: 1.5
});

const InputSwitchHelperText = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'helperText'
})(({ theme }) => ({
  color: theme.palette.neutral[600],
  paddingTop: 4,
  paddingLeft: 16,
  paddingBottom: 24
}));

const MapInputSwitch = ({ name, ...props }: InputSwitchProps) => {
  const [mapSettings, setMapSettings] = useAtom(mapSettingsAtom);
  const { map } = useMapContext();
  const value = mapSettings?.[name as keyof typeof mapSettings];

  const onChange = (newValue: unknown) => {
    const newSettings = {
      ...mapSettings,
      [`${name}`]: newValue
    };

    if (map && map?.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS) {
      map?.setZoom(REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS);
    }

    localStorage.setItem('mapSettings', JSON.stringify(newSettings));

    setMapSettings(newSettings);
  };

  return <FullWidthInputSwitch value={value} onChange={onChange} {...props} />;
};

const SettingsMenu = ({
  anchorElement,
  handleClose
}: {
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}) => {
  const open = !!anchorElement;

  return (
    <Menu
      id='settings-menu'
      anchorEl={anchorElement}
      open={open}
      onClose={handleClose}
      BackdropProps={{
        sx: {
          background: 'transparent'
        }
      }}
      sx={{
        top: 12
      }}
    >
      <Stack
        sx={{
          width: '360px',
          display: 'flex',
          alignItems: 'center',
          pl: 1,
          pr: 2,
          mt: 1
        }}
      >
        <Stack direction='column'>
          <MapInputSwitch
            name='shouldShowSnapToRoad'
            label='Snap-to-road tonen'
            labelPlacement='start'
          />
          <InputSwitchHelperText variant='body2'>
            Wanneer je inzoomt (vanaf zoom level {REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS}),
            laat de kaart de snap-to-road voor alle gebouwen zien.
          </InputSwitchHelperText>
        </Stack>
      </Stack>
    </Menu>
  );
};

export default SettingsMenu;
