import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          ...outerTheme.typography.body1,
          lineHeight: 1.5,
          backgroundColor: outerTheme.palette.neutral.dark,
          boxShadow: 'none',
          padding: outerTheme.spacing(0.75, 3),
          [outerTheme.breakpoints.up('sm')]: {
            flexGrow: 'initial',
            minWidth: 100
          }
        }
      }
    }
  }
});

const componentSnackbarTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentSnackbarTheme;
