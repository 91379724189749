import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { MapControlButton, MapControlContainer } from '../styled';

interface ExtendedDocument extends Omit<Document, 'documentElement'> {
  mozFullScreenEnabled: boolean;
  msFullscreenEnabled: boolean;
  webkitFullscreenEnabled: boolean;
  mozFullScreenElement: Element;
  msFullscreenElement: Element;
  webkitFullscreenElement: Element;
  msExitFullscreen: () => Promise<void>;
  webkitCancelFullScreen: () => void;
  mozRequestFullScreen: () => void;
  msRequestFullscreen: () => void;
  webkitRequestFullscreen: () => void;
  documentElement: {
    requestFullscreen: () => void;
    mozRequestFullScreen: () => void;
    msRequestFullscreen: () => void;
    webkitRequestFullscreen: () => void;
  };
  exitFullscreen: () => Promise<void>; // Update the return type to Promise<void>
  mozCancelFullScreen: () => void;
}

const FullScreenButton = () => {
  const WINDOW_DOCUMENT = (window.document || document) as unknown as ExtendedDocument;

  const isFullScreenSupported = !!(
    WINDOW_DOCUMENT.fullscreenEnabled ||
    WINDOW_DOCUMENT.mozFullScreenEnabled ||
    WINDOW_DOCUMENT.msFullscreenEnabled ||
    WINDOW_DOCUMENT.webkitFullscreenEnabled
  );
  const isFullScreen =
    WINDOW_DOCUMENT.fullscreenElement ||
    WINDOW_DOCUMENT.mozFullScreenElement ||
    WINDOW_DOCUMENT.webkitFullscreenElement ||
    WINDOW_DOCUMENT.msFullscreenElement;
  const fullscreenToggle = () => {
    if (isFullScreen) {
      if (WINDOW_DOCUMENT.exitFullscreen) {
        WINDOW_DOCUMENT.exitFullscreen();
      } else if (WINDOW_DOCUMENT.mozCancelFullScreen) {
        WINDOW_DOCUMENT.mozCancelFullScreen();
      } else if (WINDOW_DOCUMENT.msExitFullscreen) {
        WINDOW_DOCUMENT.msExitFullscreen();
      } else if (WINDOW_DOCUMENT.webkitCancelFullScreen) {
        WINDOW_DOCUMENT.webkitCancelFullScreen();
      }
    } else if (WINDOW_DOCUMENT?.documentElement.requestFullscreen) {
      WINDOW_DOCUMENT?.documentElement.requestFullscreen();
    } else if (WINDOW_DOCUMENT?.documentElement?.mozRequestFullScreen) {
      WINDOW_DOCUMENT?.documentElement?.mozRequestFullScreen();
    } else if (WINDOW_DOCUMENT.documentElement.msRequestFullscreen) {
      WINDOW_DOCUMENT.documentElement.msRequestFullscreen();
    } else if (WINDOW_DOCUMENT.documentElement.webkitRequestFullscreen) {
      WINDOW_DOCUMENT.documentElement.webkitRequestFullscreen();
    }
  };

  return (
    <MapControlButton onClick={fullscreenToggle} disabled={!isFullScreenSupported}>
      {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
    </MapControlButton>
  );
};

const FullScreenControl = () => (
  <MapControlContainer>
    <FullScreenButton />
  </MapControlContainer>
);

export default FullScreenControl;
