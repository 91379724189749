import { createSvgIcon } from '@mui/material/utils';

const IconRoutetitan = createSvgIcon(
  [
    <rect width='32' height='32' fill='#1329FE' rx='2' key='0' />,
    <path
      fill='#fff'
      d='M24 23a6.9985 6.9985 0 0 0-2.0503-4.9497A6.9985 6.9985 0 0 0 17 16v7h7Zm0-8a6.9985 6.9985 0 0 0-2.0503-4.9497A6.9989 6.9989 0 0 0 17 8v7h7ZM9 16h7v7H9v-7Zm0-8h7v7H9V8Z'
      key='1'
    />
  ],
  'IconRoutetitan'
);

export default IconRoutetitan;
