import { Tooltip } from '@mui/material';

import ToolbarIcon from './ToolbarIcon';

const AdvancedIconButton = ({ tooltip, icon, ...props }) => (
  <Tooltip title={tooltip}>
    <span>
      <ToolbarIcon {...props}>{icon}</ToolbarIcon>
    </span>
  </Tooltip>
);

export default AdvancedIconButton;
