import { useCallback } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Box } from '@mui/material';

const useSearchHighlighting = () =>
  useCallback((title: string, searchValue: string) => {
    const matchedOptions = match(title, searchValue, { insideWords: true });
    const matchedOptionsParts = parse(title, matchedOptions);

    return (
      <Box display='flex'>
        {matchedOptionsParts.map((matched, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            component='span'
            className='highlighted-text'
            sx={{
              fontWeight: matched.highlight ? 700 : 400
            }}
          >
            {matched.text}
          </Box>
        ))}
      </Box>
    );
  }, []);

export default useSearchHighlighting;
