import { outlinedInputClasses, styled, TextField } from '@mui/material';

const SearchInput = styled(TextField)(({ theme, value }) => ({
  width: 248,
  background: value ? theme.palette.common.white : theme.palette.neutral['50'],
  borderRadius: 1,
  [`.${outlinedInputClasses.focused}`]: {
    background: theme.palette.common.white,
    '& .MuiInputAdornment-positionStart svg': {
      color: theme.palette.primary.dark
    }
  },
  [`.${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.25, 1.75)
  },
  '&:hover': {
    background: theme.palette.neutral['50']
  }
}));

export default SearchInput;
