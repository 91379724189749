import { fabClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    neutral?: true;
  }
}

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiFab: {
      defaultProps: {
        variant: 'extended'
      },
      styleOverrides: {
        extended: ({ ownerState }) => ({
          ...outerTheme.typography.subtitle2,
          display: 'flex',
          width: 'auto',
          padding: outerTheme.spacing(1.5, 2.5, 1.5, 2),
          gap: 14,
          boxShadow: outerTheme.shadows[1],
          lineHeight: 1.05,
          borderRadius: 100,
          ...(ownerState.color === 'neutral' && {
            background: outerTheme.palette.neutral.contrastText,
            color: outerTheme.palette.neutral.main,
            '&:hover': {
              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08))'
            }
          }),
          [`&.${fabClasses.extended}.${fabClasses.disabled}`]: {
            ...(ownerState.color === 'primary' && {
              background: outerTheme.palette.primary.main,
              opacity: 0.36,
              boxShadow: outerTheme.shadows[1],
              color: outerTheme.palette.primary.contrastText
            }),
            ...(ownerState.color === 'neutral' && {
              background: outerTheme.palette.neutral.contrastText,
              opacity: 0.36,
              boxShadow: outerTheme.shadows[1],
              color: outerTheme.palette.neutral.main
            })
          }
        })
      }
    }
  }
});

const componentFabTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions(outerTheme));

export default componentFabTheme;
