import { useAtom } from 'jotai';
import { USER_AUTH_STATUS } from '@constants';
import { userAuthStatusAtom } from '../store';
import PageLoading from '../components/common/PageLoading';

const useLayoutLoading = () => {
  const [userAuthStatus] = useAtom(userAuthStatusAtom);
  if (userAuthStatus === USER_AUTH_STATUS.PENDING) {
    return <PageLoading />;
  }
};

export default useLayoutLoading;
