import { Box, Grid, Link, Skeleton } from '@mui/material';
import { useAtom } from 'jotai';
import { IBuilding } from '@types';
import { SectionLabel, SectionValue } from '@components/common/section';
import { buildingPostalCodesAtom } from '@store';
import { v4 } from 'uuid';
import { OpenInNew } from '@mui/icons-material';

const SectionItem = ({
  label,
  value,
  link,
  ...props
}: {
  label: string;
  value: string | number;
  link?: string;
}) => (
  <Grid item xs={5.5} {...props}>
    <SectionLabel>{label}</SectionLabel>
    <SectionValue
      {...(link && {
        component: Link,
        href: link,
        target: '_blank'
      })}
    >
      {value} {link && <OpenInNew sx={{
        fontSize: 14,
        color: 'black'
      }} />}
    </SectionValue>
  </Grid>
);

const BuildingInformationPlaceholder = () => {
  return (
    <Box sx={{ pt: 2 }}>
      {Array.from({ length: 5 }).map(() => {
        return (
          <Box key={v4()} sx={{ mt: 2 }}>
            <Skeleton variant='text' width='90%' height={20} /> {/* Postcode */}
          </Box>
        );
      })}
    </Box>
  );
};

const BuildingSection = ({ building }: { building: IBuilding }) => {
  const [buildingPostalCodes] = useAtom(buildingPostalCodesAtom);
  const postalCodes =
    buildingPostalCodes && buildingPostalCodes.length > 0 ? buildingPostalCodes.join(', ') : '-';

  const isLoading = !buildingPostalCodes;

  return isLoading ? (
    <BuildingInformationPlaceholder />
  ) : (
    <Grid container gap={2} pt={1.5}>
      {[
        {
          label: 'Postcode',
          value: postalCodes
        },
        {
          label: 'Identificatienummer',
          value: `0${building?.id}`,
          link: `https://bagviewer.kadaster.nl/lvbag/bag-viewer/?searchQuery=0${building?.id}&objectId=${building?.id}`
        }
      ].map((item) => (
        <SectionItem key={item.label} {...item} />
      ))}
    </Grid>
  );
};

export default BuildingSection;
