import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: outerTheme.palette.neutral.light,
          padding: outerTheme.spacing(1.25, 1.5),
          boxShadow: outerTheme.shadows[3],
          ...outerTheme.typography.body4
        }
      }
    }
  }
});

const componentTooltipTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentTooltipTheme;
