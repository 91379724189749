import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';

interface ButtonWithLoadingProps extends ButtonProps {
  loading: boolean;
  dataCy?: string;
}

const ButtonWithLoading = ({ children, dataCy, ...props }: ButtonWithLoadingProps) => {
  return (
    <LoadingButton data-cy={dataCy} {...props}>
      {children}
    </LoadingButton>
  );
};

export default ButtonWithLoading;
