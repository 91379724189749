/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Checkbox, Grid, SxProps, Tooltip } from '@mui/material';
import CypressIds from '../../../cypressIds';

const LeftSideCell = ({
  canBecomeCheckable,
  isCurrentItem,
  item,
  defaultIcon,
  color,
  selectedListItems,
  setSelectedItems,
  sx,
  index
}: {
  canBecomeCheckable?: boolean;
  isCurrentItem?: boolean;
  item: any;
  defaultIcon: any;
  color?: string;
  selectedListItems?: any[];
  setSelectedItems?: any;
  sx?: SxProps;
  index?: number;
}) => {
  const isSelectModeEnabled = selectedListItems?.length > 0;
  const isItemSelected =
    isSelectModeEnabled && !!selectedListItems?.find((listItem) => listItem.id === item.id);

  const isCheckable = isCurrentItem && canBecomeCheckable;
  const isColored = isCurrentItem || isCheckable || item.id;

  return (
    <Grid
      item
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        ...sx
      }}
      component={isItemSelected ? 'span' : 'div'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isCheckable || isSelectModeEnabled ? (
        <Checkbox
          sx={{
            p: 1,
            '& .MuiSvgIcon-root': { fontSize: (theme) => theme.typography.pxToRem(24) }
          }}
          data-cy={`${CypressIds.COMMON_CHECKBOX}-${index}`}
          checked={!!isItemSelected}
          onChange={(e, checked) => {
            if (checked) {
              setSelectedItems((items) => items.concat(item));
            } else {
              setSelectedItems((items) =>
                items.filter((selectedItem) => selectedItem.id !== item.id)
              );
            }
          }}
        />
      ) : (
        <Box
          component={defaultIcon}
          sx={(theme) => ({
            ...(color
              ? {
                  color
                }
              : {
                  color: isColored ? theme.palette.neutral.light : theme.palette.neutral[400]
                }),
            fontSize: theme.typography.pxToRem(24),
            m: 1
          })}
        />
      )}
    </Grid>
  );
};

export default LeftSideCell;
