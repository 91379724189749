import { listItemTextClasses, menuItemClasses, typographyClasses } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: 40,
          gap: outerTheme.spacing(1),
          padding: outerTheme.spacing(1, 1.25),

          [`& .${listItemTextClasses.root} .${typographyClasses.root}`]: {
            ...outerTheme.typography.body2,
            lineHeight: 1.6
          },
          [`&.${menuItemClasses.disabled} `]: {
            background: outerTheme.palette.neutral['100']
          },
          [`&.${menuItemClasses.selected} `]: {
            background: outerTheme.palette.neutral['100']
          },
          [`&.${menuItemClasses.selected}:hover`]: {
            background: outerTheme.palette.neutral['100']
          },
          '&:hover': {
            backgroundColor: outerTheme.palette.neutral['100']
          }
        }
      }
    }
  }
});

const componentMenuItemTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentMenuItemTheme;
