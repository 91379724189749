import { CircularProgress, Grid, SxProps } from '@mui/material';
import CypressIds from '../../../cypressIds';

const CircularLoading = ({
  size = 24,
  sx,
  splash
}: {
  size?: number;
  sx?: SxProps;
  splash?: boolean;
}) => (
  <Grid
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...(!splash && {
        py: 2
      }),
      ...sx
    }}
  >
    <CircularProgress
      data-cy={CypressIds.COMMON_GLOBAL_LOADING}
      size={size}
      sx={{ margin: 'auto' }}
    />
  </Grid>
);

export default CircularLoading;
