import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import CypressIds from '../../../cypressIds';

const CloseButton = ({ onClick, sx, ...otherProps }) => (
  <IconButton
    onClick={onClick}
    sx={{
      mr: 2,
      ...sx
    }}
    {...otherProps}
    data-cy={CypressIds.COMMON_CLOSE_BUTTON}
  >
    <Close
      sx={{
        fontSize: (theme) => theme.typography.pxToRem(24)
      }}
    />
  </IconButton>
);

export default CloseButton;
