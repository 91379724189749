import ThemeProvider from '@theme/provider';
import ErrorFallback from '@components/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { PostHogProvider } from 'posthog-js/react';
import { APP_POSTHOG_API_KEY } from '@constants';
import Modules from './modules';
import Providers from './providers';

const App = () => {
  return (
    <PostHogProvider
      apiKey={APP_POSTHOG_API_KEY}
      options={{
        api_host: 'https://eu.i.posthog.com',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
            color: false,
            date: false,
            'datetime-local': false,
            email: false,
            month: false,
            number: false,
            range: false,
            search: false,
            tel: false,
            text: false,
            time: false,
            url: false,
            week: false,
            textarea: false,
            select: false
          }
        }
      }}
    >
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Providers>
            <Modules />
          </Providers>
        </ErrorBoundary>
      </ThemeProvider>
    </PostHogProvider>
  );
};

export default App;
