/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines-per-function */
/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import MapContext from 'contexts/MapContext';
import { getLocations, updateLocation } from '@services/buildingService';
import PageHeader from '@components/PageHeader';
import { LocationType, LocationTypeEn, LocationTypeOptions } from '@constants';
import { convertType } from '@helpers/formatLocations';
import { buildingAddressesAtom, isLocationsLoadingAtom, locationsAtom } from '@store';
import ButtonWithLoading from '@components/ui-kit/ButtonWithLoading';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import CircularLoading from '@components/ui-kit/CircularLoading';
import { addLocationMarkers, clearAllMarkers, getMapBbox } from '@utils/map';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import CypressIds from '../../../../cypressIds';

const LocationUpdatePage = () => {
  const { locationId } = useParams();
  const [error, setError] = useState<{
    value: string;
    fieldName: string;
  } | null>(null);
  const { map } = useContext(MapContext);
  const [type, setType] = useState<LocationTypeEn | null>(null);
  const [name, setName] = useState<string>('');
  const [locations, setLocations] = useAtom(locationsAtom);
  const [isLocationsLoading, setIsLocationsLoading] = useAtom(isLocationsLoadingAtom);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const currentLocationId = useRef<string | null>(null);
  const [marker, setMarker] = useState<maplibregl.Marker | null>(null);
  const [, setBuildingAddresses] = useAtom(buildingAddressesAtom);
  const navigate = useAdvancedNavigate();
  const changeRouterParams = useChangeRouterSearchParams();
  const [isLocationUpdating, setIsLocationUpdating] = useState(false);

  useEffect(() => {
    if ((currentLocationId?.current === locationId && marker) || !map || !locations) return;

    if (!locationId) return navigate('/');
    currentLocationId.current = locationId;

    clearAllMarkers(map);
    const params = new URLSearchParams(window.location.search);
    const [lng = 0, lat = 0, locationName = '', locationTypeFromWindow = LocationTypeEn.Parking] =
      params.get('feat')?.split(',') || [];
    const locationType = convertType(locationTypeFromWindow as LocationType) as LocationTypeEn;
    setName(locationName as string);
    setCoordinates([parseFloat(+lng), parseFloat(+lat)]);
    setType(locationType);

    const glMarker = addLocationMarkers(locations, map, locationId);
    const coordsElement = document.getElementById('coordinates') as HTMLElement;
    if (Array.isArray(glMarker)) {
      return;
    }
    glMarker?.on('drag', (e) => {
      coordsElement.style.display = 'block';
      const { x, y } = e.target._pos;
      const { lng, lat } = e.target._lngLat;
      coordsElement.style.top = `${y - 15}px`;
      coordsElement.style.left = `${x + 230}px`;
      coordsElement.innerHTML = `${lng.toFixed(7)}, ${lat.toFixed(7)}`;
      setCoordinates([parseFloat(lng.toFixed(7)), parseFloat(lat.toFixed(7))]);
    });

    glMarker?.on('dragend', () => {
      coordsElement.style.display = 'none';
      const { lng, lat } = glMarker.getLngLat();
      setCoordinates([parseFloat(lng.toFixed(7)), parseFloat(lat.toFixed(7))]);
    });
    setMarker(glMarker);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, isLocationsLoading, locationId, locations, map, marker]);

  const fetchLocations = useCallback(async () => {
    setIsLocationsLoading(true);
    const bbox = getMapBbox(map);
    getLocations(bbox)
      .then(({ features }) => {
        setLocations(features);
      })
      .finally(() => {
        setIsLocationsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(
    () => () => {
      marker?.remove();
      (document.getElementById('coordinates') as HTMLElement).style.display = 'none';
    },
    [marker]
  );

  useEffect(() => {
    if (!locations && map) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, map]);

  const handleBack = () => {
    Promise.resolve()
      .then(() => {
        changeRouterParams('feat');
      })
      .then(() => {
        navigate(`/`);
        if (locations) {
          addLocationMarkers(locations, map);
        }
      });
  };

  // eslint-disable-next-line consistent-return
  const handleSave = () => {
    if (!name) {
      return setError({
        value: 'Naam is verplicht!',
        fieldName: 'name'
      });
    }
    if (!type) {
      return setError({
        value: 'Type is verplicht!',
        fieldName: 'type'
      });
    }
    if (!coordinates || coordinates.length !== 2 || coordinates[0] === 0 || coordinates[1] === 0) {
      return setError({
        value: 'Coördinatenveld is verplicht!',
        fieldName: 'coordinates'
      });
    }
    setIsLocationUpdating(true);
    const payload = {
      title: name,
      coordinates,
      type: convertType(type as LocationTypeEn)
    };
    updateLocation(locationId as string, payload)
      .then(() => {
        setBuildingAddresses(null);
        fetchLocations().then(() => {
          handleBack();
        });
      })
      .finally(() => {
        setIsLocationUpdating(false);
      });
  };

  const isLoading = isLocationsLoading && !locations;

  return (
    <Grid mb={3}>
      <PageHeader onClick={handleBack} label='Locatie bewerken' />
      <Grid p={3} display='flex' flexDirection='column' gap={0.5}>
        {isLoading ? (
          <CircularLoading />
        ) : (
          <>
            <Grid mb={3}>
              <FormControl fullWidth>
                <TextField
                  label='Naam'
                  {...(error?.fieldName === 'name' && {
                    error: true,
                    helperText: error?.value
                  })}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  inputProps={{
                    'data-cy': CypressIds.LOCATION_NAME_INPUT
                  }}
                />
              </FormControl>
            </Grid>
            <Grid mb={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={type}
                  onChange={(e, value) => setType(value)}
                  options={LocationTypeOptions.map((item) => item.id)}
                  renderOption={(props, option) => {
                    return <MenuItem {...props}>{option}</MenuItem>;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Type'
                      data-cy={CypressIds.LOCATION_TYPE_SELECT}
                      {...(error?.fieldName === 'type' && {
                        error: true,
                        helperText: error?.value
                      })}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid mb={3}>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <TextField
                  onChange={(e) => setCoordinates(e.target.value.split(',').map(Number))}
                  value={`${coordinates[0]}, ${coordinates[1]}`}
                  sx={{
                    '& fieldset': {
                      border: '1px solid #e2e8f0',
                      borderRadius: 0,
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4
                    },
                    pointerEvents: 'none'
                  }}
                  label='Coördinaten'
                  {...(error?.fieldName === 'coordinates' &&
                    !marker && {
                      error: true,
                      helperText: error?.value
                    })}
                />
              </Grid>
              {marker && (
                <Grid
                  sx={{
                    mt: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: 'text.secondary'
                  }}
                >
                  <InfoOutlined />
                  <Typography variant='caption'>
                    U kunt de markering slepen om de locatie te wijzigen
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid display='flex' justifyContent='flex-end' pt={3} gap={3}>
              <Button type='button' onClick={handleBack} variant='text'>
                Annuleren
              </Button>
              <ButtonWithLoading
                loading={isLocationUpdating}
                type='button'
                onClick={handleSave}
                variant='contained'
                data-cy={CypressIds.COMMON_CONFIRM_BUTTON}
              >
                Opslaan
              </ButtonWithLoading>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default LocationUpdatePage;
