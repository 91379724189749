/* eslint-disable import/extensions */
import { version } from '../../package.json';

export const APP_BASE_API_URL = window.location.origin.includes('routetitan')
  ? import.meta.env.APP_BASE_API_URL
  : `${window.location.origin}/api`;

export const {
  APP_ENVIRONMENT,
  APP_FIREBASE_API_KEY,
  APP_FIREBASE_PROJECT_ID,
  APP_STADIA_MAPS_API_KEY,
  APP_MAPBOX_MAPS_API_KEY,
  APP_POSTHOG_API_KEY
} = import.meta.env;
export const APP_VERSION = version;

export const PROCESS_STATUS = {
  UNKNOWN: 'UNKNOWN',
  LOADING: 'LOADING',
  READY: 'READY',
  FAILED: 'FAILED'
};
