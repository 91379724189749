/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, IconButton, InputAdornment, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormInputText from '../../../../components/common/Input-text';
import PasswordVisibility from '../../../../components/PasswordVisibility';
import authValidationSchema from '../../validation';
import CypressIds from '../../../../../cypressIds';

const Form = (props: { onSubmit: any; isSubmitting: any }) => {
  const { onSubmit, isSubmitting } = props;
  const [showPassword, setShowPassword] = useState(false);

  const formMethods = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(authValidationSchema)
  });

  // Functions
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={2} sx={{ py: 3, width: '100%' }}>
        <Grid sx={{ height: 70 }}>
          <FormInputText
            autoFocus
            name='email'
            label='Email'
            fullWidth
            data-cy={CypressIds.EMAIL_INPUT}
            autoComplete='email'
            type='email'
          />
        </Grid>
        <Grid sx={{ height: 70 }}>
          <FormInputText
            name='password'
            label='Password'
            fullWidth
            type={showPassword ? 'text' : 'password'}
            data-cy={CypressIds.PASSWORD_INPUT}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    <PasswordVisibility showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid container sx={{ paddingTop: 5, justifyContent: 'flex-end' }}>
          <Grid item xs={12} container justifyContent='flex-end'>
            <LoadingButton
              data-cy={CypressIds.LOGIN_BUTTON}
              loading={formMethods.formState.isSubmitting || isSubmitting}
              variant='contained'
              size='large'
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
};

export default Form;
