import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material' {
  interface TypeText {
    primaryLight?: string;
    secondaryLight?: string;
    landingGrey?: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface PaletteColor {
    '900'?: string;
    '800'?: string;
    '700'?: string;
    '600'?: string;
    '500'?: string;
    '400'?: string;
    '300': string;
    '200'?: string;
    '150'?: string;
    '100': string;
    '50'?: string;
    '500-8'?: string;
    '500-12'?: string;
    '600-8'?: string;
    '600-12'?: string;
    '600-40'?: string;
    '900-8'?: string;
    '900-12'?: string;
    '0-56'?: string;
    landingGrey?: string;
    secondaryGrey?: string;
    primaryLight?: string;
    secondaryLight?: string;
  }

  interface SimplePaletteColorOptions {
    '900'?: string;
    '800'?: string;
    '700'?: string;
    '600'?: string;
    '500'?: string;
    '400'?: string;
    '300'?: string;
    '200'?: string;
    '150'?: string;
    '100'?: string;
    '50'?: string;
    '500-8'?: string;
    '500-12'?: string;
    '600-8'?: string;
    '600-12'?: string;
    '600-40'?: string;
    '900-8'?: string;
    '900-12'?: string;
    '0-56'?: string;
    landingGrey?: string;
    secondaryGrey?: string;
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#4859FE',
      main: '#1329FE',
      dark: '#0114CB',
      '800': '#010D84',
      '400': '#7B87FE',
      '300': '#AEB5FF',
      '200': '#D6DAFF',
      '100': '#EDEFFF',
      '50': '#F8F9FF',
      '600-8': '#1329FE14',
      '600-12': '#1329FE1E',
      '600-40': '#1329FE66',
      contrastText: '#FFFFFF'
    },
    neutral: {
      light: '#606165',
      main: '#4D4D50',
      dark: '#202124',
      '600': '#7D7E82',
      '500': '#9D9EA2',
      '400': '#C2C3C7',
      '300': '#DBDCE1',
      '200': '#EAEBF0',
      '150': '#F0F0F0',
      '100': '#F4F5F9',
      '50': '#FAF9F9',
      '900-8': '#20212414',
      '900-12': '#2021241E',
      '0-56': '#FFFFFF8F',
      landingGrey: '#939AAA',
      secondaryGrey: '#DADADA',
      contrastText: '#FFFFFF'
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF'
    },
    text: {
      primary: '#202124',
      secondary: '#606165',
      disabled: '#C2C3C7',
      primaryLight: '#4D4D50',
      secondaryLight: '#7D7E82',
      landingGrey: '#939AAA'
    },
    success: {
      light: '#11B163',
      main: '#07A35A',
      dark: '#057A43',
      '400': '#4ABC7A',
      '300': '#6DC790',
      '200': '#9BD6B0',
      '100': '#C5E7D1',
      '50': '#E8F2EC',
      '600-12': '#07A35A1E',
      contrastText: '#FFFFFF'
    },
    warning: {
      light: '#FFC93F',
      main: '#FEB700',
      dark: '#C28F16',
      '300': '#FFD66F',
      '200': '#FFE093',
      '100': '#FFE8AE',
      '50': '#FFEEC2',
      '500-12': '#FEB7001E',
      contrastText: '#FFFFFF'
    },
    error: {
      light: '#E4635A',
      main: '#D93024',
      dark: '#A9362E',
      '700': '#8A322C',
      '300': '#EA8A83',
      '200': '#F0A7A2',
      '100': '#F3BDB9',
      '50': '#F6CECB',
      '500-8': '#D9302414',
      '500-12': '#D930241E',
      contrastText: '#FFFFFF'
    },
    divider: '#EAEBF0',
    action: {
      disabled: '#EAEBF0'
    }
  }
};

const paletteTheme = (outerTheme: Theme) => createTheme(outerTheme, themeOptions);

export default paletteTheme;
