interface ImageProps {
  alt: string;
  className?: string;
  image: {
    fallback: string;
    height: number;
    pngSrcset: string;
    webpSrcset: string;
    width: number;
  };
}

const Image = (props: ImageProps) => {
  const {
    alt,
    image: { fallback, height, pngSrcset, webpSrcset, width },
    className = ''
  } = props;
  return (
    <picture
      style={{
        height,
        width,
        display: 'inline-block',
        fontSize: 0
      }}
      className={className}
    >
      <source type='image/webp' srcSet={webpSrcset} />
      <source type='image/png' srcSet={pngSrcset} />
      <img src={fallback} alt={alt} height={height} width={width} />
    </picture>
  );
};

export default Image;
