import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const themeOptions = (outerTheme: Theme): ThemeOptions => ({
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: outerTheme.shadows[1]
        }
      }
    }
  }
});

const componentPopoverTheme = (outerTheme: Theme) =>
  createTheme(outerTheme, themeOptions(outerTheme));

export default componentPopoverTheme;
