import { Box } from '@mui/material';
import { RefObject } from 'react';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import useMapContext from '@hooks/useMapContext';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import MapLayerSettings from './Layer';
import MapControls from './Controls';
import CypressIds from '../../../cypressIds';

interface ContextMenuItem {
  label: string;
  action: () => void;
}

export const ContextMenu = ({ rightDrawerRef }: { rightDrawerRef: HTMLDivElement | null }) => {
  const [anchorCoords, setAnchorCoords] = useState(null);
  const { map: mapInstance } = useMapContext();
  const isContextMenuOpen = Boolean(anchorCoords);
  const navigate = useAdvancedNavigate();
  const changeRouterSearchParams = useChangeRouterSearchParams();
  const contextMenuItems = useRef<ContextMenuItem[]>([]);

  const contextMenuRef = useRef(null);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.on('contextmenu', (e) => {
        e.preventDefault();
        const { lng, lat } = e.lngLat;
        const zoom = mapInstance.getZoom();
        const heading = mapInstance.getBearing();
        const fixedLat = +lat.toFixed(7);
        const fixedLng = +lng.toFixed(7);
        const streetViewUrl = `https://www.google.nl/maps/@?api=1&map_action=pano&viewpoint=${fixedLat},${fixedLng}&heading=${heading}&pitch=0&fov=100`;

        const googleMapsUrl = `https://www.google.nl/maps/@${fixedLat},${fixedLng},${zoom}z`;

        contextMenuItems.current = [
          {
            label: 'Bekijk in Google Street View',
            action: () => {
              window.open(streetViewUrl, '_blank', 'noopener');
            }
          },
          {
            label: 'Bekijk in Google Maps',
            action: () => {
              window.open(googleMapsUrl, '_blank', 'noopener');
            }
          },
          {
            label: 'Nieuwe locatie toevoegen',
            action: () => {
              changeRouterSearchParams('currentLocation', `${fixedLat},${fixedLng}`);
              navigate('buildings/locations/create/');
            }
          }
        ] as ContextMenuItem[];

        setAnchorCoords({
          x: e.originalEvent.clientX,
          y: e.originalEvent.clientY
        });
      });

      mapInstance?.on('click', () => {
        setAnchorCoords(null);
      });

      rightDrawerRef?.addEventListener('click', () => {
        setAnchorCoords(null);
      });
      rightDrawerRef?.addEventListener('contextmenu', () => {
        setAnchorCoords(null);
      });
    }

    return () => {
      if (mapInstance) {
        mapInstance.off('contextmenu', () => {});
      }
      if (rightDrawerRef) {
        rightDrawerRef.removeEventListener('click', () => {});
        rightDrawerRef.removeEventListener('contextmenu', () => {});
      }
    };
  }, [changeRouterSearchParams, mapInstance, navigate, rightDrawerRef]);

  return (
    <Box
      hidden={!isContextMenuOpen}
      ref={contextMenuRef}
      sx={{
        position: 'fixed',
        top: anchorCoords?.y || 0,
        left: anchorCoords?.x || 0,
        backgroundColor: 'white',
        borderRadius: 1,
        py: 1,
        boxShadow: 1
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorCoords(null);
      }}
      onBlur={() => {
        setAnchorCoords(null);
      }}
    >
      {contextMenuItems.current.map((item) => (
        <Box
          key={item.label}
          sx={{
            display: 'block',
            padding: 1,
            textDecoration: 'none',
            minWidth: 200,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'primary.light',
              color: 'white'
            }
          }}
          onClick={() => {
            item.action();
            setAnchorCoords(null);
          }}
        >
          {item.label}
        </Box>
      ))}
    </Box>
  );
};

const MapContainer = ({ mapContainerRef }: { mapContainerRef: RefObject<HTMLDivElement> }) => {
  return (
    <>
      <Box id='map-info' />
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1001,
          p: 1,
          backgroundColor: theme.palette.neutral.dark,
          color: theme.palette.common.white,
          display: 'none'
        })}
        id='coordinates'
      />
      <Box
        ref={mapContainerRef}
        className='map'
        id='map'
        data-cy={CypressIds.MAP_CONTAINER}
        sx={{
          width: 'calc(100% - 448px)',
          marginLeft: '448px'
        }}
      />
      <MapControls />
      <MapLayerSettings />
    </>
  );
};

export default MapContainer;
